import Konva from 'konva';
import React from 'react';
import styled from 'styled-components';
import { getCleanProps } from './answer-tools';
import HustleCanvas from './hustle-canvas';
import { validateAll } from './validate-question';

let iframeStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
};

var Hustlegraphic = styled(
  class extends React.Component {
    state = {
      mounted: false,
      graphicMade: false,
      iFrameLoaded: false,
      color: 'green',
      cleanProps: null,
    };
    iFrameRef = React.createRef();
    makeGraphic(cleanProps) {
      if (this.state.iFrameLoaded && this.iFrameRef.current) {
        let iFrameWindow = this.iFrameRef.current.contentWindow;
        iFrameWindow.hustleGraphic.makeGraphic(cleanProps);
        if (this.props.setCanvas) {
          this.props.setCanvas(iFrameWindow.document.getElementById('canvas'));
        }
        this.setState({ graphicMade: true });
      }
    }
    componentDidMount() {
      if (!this.state.cleanProps) {
        this.setCanvas();
      }
    }
    setCanvas() {
      if (!this.state.graphicMade) {
        let props = {
          info: this.props.info,
          answers: this.props.answers,
          questions: this.props.questions,
        };
        let errors = validateAll(props);
        if (errors.length === 0) {
          let cleanProps = getCleanProps(props);
          if (!this.state.cleanProps) {
            this.setState({ cleanProps });
          }

          this.makeGraphic(cleanProps);
        }
      }
    }
    componentDidUpdate(lastProps, _lastState) {
      // if (
      //   this.state.mounted &&
      //   !this.state.graphicMade &&
      //   this.state.iFrameLoaded
      // )
      this.setCanvas();
      if (this.props !== lastProps) {
      } else {
      }
    }
    handleClick = () => {
      this.setState({
        color: Konva.Util.getRandomColor(),
      });
    };
    render() {
      let numQuestions =
        getCleanProps({
          info: this.props.info,
          answers: this.props.answers,
          questions: this.props.questions,
        }).questions.length || 0;
      let wrapClass = ' height-';
      if (numQuestions <= 2) {
        wrapClass += '951';
      } else if (
        numQuestions <= 4 ||
        (numQuestions >= 7 && numQuestions <= 9)
      ) {
        wrapClass += '1611';
      } else if (numQuestions >= 5 && numQuestions <= 6) {
        wrapClass += '1171';
      }
      const { updateImageLink, questions, info, answers, setCanvas, ...props } =
        this.props;

      return (
        <div {...props} className={props.className + wrapClass}>
          {this.state.cleanProps && (
            <HustleCanvas cleanProps={this.state.cleanProps} />
          )}
        </div>
      );
    }
  }
)`
  position: relative;
  width: 100%;
  padding-bottom: ${1 * (4 / 3)}%;
  &.height-951 {
    padding-bottom: ${1 * (951 / 1536)}%;
  }
  &.height-1171 {
    padding-bottom: ${1 * (1171 / 1536)}%;
  }
  &.height-1611 {
    padding-bottom: ${1 * (1611 / 1536)}%;
  }
`;

export default Hustlegraphic;
