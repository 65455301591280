import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'sports-wh': {
    ...baseStyle,
    name: 'Sports WH',
    thumbnail: '/images/mats/mat_005_wh-thumb.jpg',
    bgImage: '/images/mats/mat_005_wh.jpg',
    overlayImage: '/images/mats/mat_005_wh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'proxima-nova, Georgia, serif',
      spacing: 0.11,
      fontWeight: '600',
      pos: { x: 0.5, y: 0.6778114478 },
      maxWidth: 0.583333333333333,
      fontColor: '#000',
      fontSize: 0.03233333333,
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.8809575 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.92635 },
    },
  },
  'sports-hsh': {
    ...baseStyle,
    name: 'Sports HSH',
    thumbnail: '/images/mats/mat_005_hsh-thumb.jpg',
    bgImage: '/images/mats/mat_005_hsh.jpg',
    overlayImage: '/images/mats/mat_005_hsh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'superclarendon, Georgia, serif',
      spacing: 0.02,
      pos: { x: 0.5, y: 0.7073512907 },
      maxWidth: 0.5490740741,
      fontColor: '#000',
      fontSize: 0.03485185185,
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.8809575 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.92635 },
    },
  },
};

export default mats;
