import React from 'react';
import styled from 'styled-components';

const InputBase: React.FunctionComponent<
  React.HTMLProps<HTMLInputElement> & React.HTMLProps<HTMLTextAreaElement>
> = (props) => {
  if (props.rows && props.rows !== 1) {
    return <textarea {...props} />;
  } else {
    return <input {...props} />;
  }
};

export const BasicInput = styled(InputBase)`
  position: relative;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0.05em;
  padding: 10px 10px;
  margin: 0;
  resize: none;
  border: 1px solid ${(props) => props.theme.formBorderColor};
  border-radius: ${(props) => props.theme.borderRadius};
  appearance: none;
  &:focus {
    padding: 8px 8px;
    border: 3px solid ${(props) => props.theme.secondaryColor};
    outline: none;
  }
  &:disabled {
    color: black;
  }
`;

export const Input = styled(BasicInput)`
  font-size: 16px;
  letter-spacing: 0em;
  margin-bottom: 5px;
  @media only screen and (min-width: 360px) {
    letter-spacing: 0.025m;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
  @media only screen and (min-width: 420px) {
    letter-spacing: 0.05em;
  }
  ${(p) => p.theme.mq1} {
    font-size: 18px;
    letter-spacing: 0.05em;
  }
`;
