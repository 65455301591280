import React from 'react';
import styled from 'styled-components';

const PageContentInnerBase = styled.div`
  /*margin-top: 10px;*/
  padding-bottom: 40px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
`;

export const PageContentInner = styled(PageContentInnerBase)`
  ${(props) => props.theme.mq1} {
    padding-left: ${(props) => props.theme.margin2}px;
    padding-right: ${(props) => props.theme.margin2}px;
  }
  ${(props) => props.theme.mq2} {
    padding-left: ${(props) => props.theme.margin3}px;
    padding-right: ${(props) => props.theme.margin3}px;
  }
`;

export const PageContentOuter = styled((props) => (
  <div {...props}>{props.children}</div>
))`
  max-width: ${(props) => props.theme.break3}px;
  ${(props) => props.theme.mq3} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const PageContent = (props) => (
  <PageContentOuter {...props}>
    <PageContentInner>{props.children}</PageContentInner>
  </PageContentOuter>
);

export const PageContentWithHeader = styled(PageContent)`
  padding-top: ${(props) => props.theme.headerHeight}px; /* .header -> height */
`;
