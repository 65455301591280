import React from 'react';
import debounce from 'debounce-promise';
import remaxTextFilter from '../lib/remax-text-filter';

const canvasWidth = 1080;
const canvasHeight = 891;
const createjs = window.createjs;

class WelcomeMat extends React.Component {
  constructor(props) {
    super();
    this.renderBuyerNameDB = debounce(this.renderBuyerName, 0);
    this.renderAgentNameDB = debounce(this.renderAgentName, 0);
    this.renderOfficeNameDB = debounce(this.renderOfficeName, 0);
    this.state = {
      bgLoaded: false,
      overlayLoaded: false,
      readyToRender: false,
    };
  }
  componentDidMount() {
    this.stage = new createjs.Stage('welcome-mat');
    this.background = new createjs.Container();
    this.overlay = new createjs.Container();
    this.buyerName = new createjs.Container();
    this.agentName = new createjs.Container();
    this.officeName = new createjs.Container();
    this.buyerName.cache(0, 0, canvasWidth, canvasHeight);

    this.setBG(this.props.matData.bgImage, this.props.matData.overlayImage);
    this.stage.addChildAt(this.background, 0);
    this.stage.addChildAt(this.buyerName, 1);
    this.stage.addChildAt(this.overlay, 2);
    this.stage.addChildAt(this.agentName, 3);
    this.stage.addChildAt(this.officeName, 4);

    this.stage.alpha = 0;
  }
  componentWillUpdate(nextProps, nextState) {
    if (
      !nextState.readyToRender &&
      nextState.overlayLoaded &&
      nextState.bgLoaded &&
      nextProps.fontsReady
    ) {
      this.setState({ readyToRender: true });
    }
  }
  componentDidUpdate(lastProps, lastState) {
    if (this.state.readyToRender) {
      if (!lastState.readyToRender) {
        // First render, no delay
        setTimeout(() => {
          this.renderBuyerName(this.props.matText);
          this.renderAgentName(this.props.agentName);
          this.renderOfficeName(this.props.officeName);
          this.stage.alpha = 1;
          this.stage.update();
        }, 0);
      } else if (this.props.agentName !== lastProps.agentName) {
        // Rendering is slow, so debounce on input
        this.renderAgentNameDB(this.props.agentName).then(() => {
          this.stage.update();
        });
      } else if (this.props.officeName !== lastProps.officeName) {
        // Rendering is slow, so debounce on input
        this.renderOfficeNameDB(this.props.officeName).then(() => {
          this.stage.update();
        });
      } else if (this.props.matText !== lastProps.matText) {
        // Rendering is slow, so debounce on input
        this.renderBuyerNameDB(this.props.matText).then(() => {
          this.stage.update();
        });
      } else if (
        this.props.matData.bgImage !== lastProps.matData.bgImage ||
        this.props.matData.overlayImage !== lastProps.matData.overlayImage
      ) {
        // Prevent old onload handlers from mucking things up
        try {
          this.background.getChildAt(0).image.onload = null;
          this.overlay.getChildAt(0).image.onload = null;
        } catch (e) {}
        // Re-render everything.
        this.setState({
          bgLoaded: false,
          overlayLoaded: false,
          readyToRender: false,
        });
        this.stage.alpha = 0;
        this.renderBuyerName(this.props.matText);
        this.renderAgentName(this.props.agentName);
        this.renderOfficeName(this.props.officeName);
        this.setBG(this.props.matData.bgImage, this.props.matData.overlayImage);
      }
    }
  }
  setBG(bgImage, overlayImage) {
    let backgroundBM = new createjs.Bitmap(this.props.matData.bgImage);
    backgroundBM.image.onload = () => {
      this.stage.update();
      this.setState({ bgLoaded: true });
    };
    this.background.removeAllChildren();
    this.background.addChild(backgroundBM);

    this.overlay.removeAllChildren();
    if (this.props.matData.overlayImage) {
      let overlayBM = new createjs.Bitmap(this.props.matData.overlayImage);
      overlayBM.image.onload = () => {
        this.stage.update();
        this.setState({ overlayLoaded: true });
      };
      this.overlay.addChild(overlayBM);
    } else {
      this.setState({ overlayLoaded: true });
    }
  }
  makeTextWithSpacing(textString, opts) {
    let textContainer = new createjs.Container();
    let textWidth = 0;
    let spacing = opts.spacing * opts.fontSize * canvasWidth;
    for (let i = 0; i < textString.length; ++i) {
      let char = textString[i];
      let text = new createjs.Text(
        char,
        `${opts.fontWeight} ${opts.fontSize * canvasWidth}px ${opts.font}`,
        opts.fontColor
      );
      text.x = textWidth;
      textContainer.addChild(text);
      textWidth +=
        text.getMeasuredWidth() + (i === textString.length - 1 ? 0 : spacing);
    }
    return { textContainer: textContainer, textWidth: textWidth };
  }
  renderAgentName(textString) {
    return this.renderText(
      textString,
      this.agentName,
      this.props.matData.agentName
    );
  }
  renderBuyerName(textString) {
    return this.renderText(
      textString,
      this.buyerName,
      this.props.matData.buyerName
    );
  }
  renderOfficeName(textString) {
    return this.renderText(
      textString,
      this.officeName,
      this.props.matData.officeName
    );
  }
  renderText(textString, container, opts) {
    // const opts = this.props.matData.buyerName;
    if (typeof textString !== 'string') {
      textString = '';
    }
    textString = remaxTextFilter(textString);
    if (opts.allCaps) {
      textString = textString.toUpperCase();
    }
    let { textContainer, textWidth } = this.makeTextWithSpacing(
      textString,
      opts
    );

    if (textWidth > opts.maxWidth * canvasWidth) {
      textContainer.scaleX = textContainer.scaleY =
        (opts.maxWidth * canvasWidth) / textWidth;
      textWidth *= textContainer.scaleX;
    }
    textContainer.x = (canvasWidth - textWidth) * opts.pos.x;
    textContainer.y = opts.pos.y * canvasHeight;

    container.removeAllChildren();
    container.addChild(textContainer);
    if (opts.blur) {
      var blurFilter = new createjs.BlurFilter(2, 2, 1);
      container.filters = [blurFilter];
      container.cache(0, 0, canvasWidth, canvasHeight);
      container.updateCache();
    } else {
      container.uncache();
    }
    return Promise.resolve(textContainer);
  }
  render() {
    return (
      <div>
        <canvas
          ref={this.props.setCanvas}
          id="welcome-mat"
          width={canvasWidth}
          height={canvasHeight}
        />
      </div>
    );
  }
}

export default WelcomeMat;
