import { ReactNode } from 'react';
import Link from '../lib/components/link';
import getCurrentYear from '../lib/currentYear';

type Props = {
  children: ReactNode;
};
export default function MainLayout({ children }: Props) {
  return (
    <>
      {children}
      <div className="footer">
        <p>© {getCurrentYear()} RE/MAX, LLC. All Rights Reserved.</p>
        <div className="terms">
          <span>
            <Link to="terms" target="_blank">
              Terms & Conditions
            </Link>
          </span>
          <span>|</span>
          <span>
            <a href="https://www.remax.com/privacy-notice" target="_blank">
              Privacy Policy (US)
            </a>
          </span>
          <span>|</span>
          <span>
            <a href="https://www.remax.ca/privacy-notice" target="_blank">
              Privacy Policy (Canada)
            </a>
          </span>
        </div>
      </div>
    </>
  );
}
