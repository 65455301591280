// @flow
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonText } from './buttons';
import styled from 'styled-components';
import isIOS from '../ios-detect';
import $ from 'jquery';
import { withRouter } from 'react-router';

const Wrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: 2000px;
  background-color: white;
`;

const ButtonWrap = styled.div`
  position: absolute;
  width: 100%;
  bottom: 32px;
`;

const HelpText = styled(
  (
    p // eslint-disable-next-line
  ) => (
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    <img
      {...p}
      src="/images/help-text.png"
      alt="On the next screen, press the Safari \\u0022Share\\u0022 button and select \\u0022Save Image.\\u0022"
    />
  )
)`
  width: 192px;
  margin-left: auto;
  margin-right: auto;
`;

class IOSHelp extends React.Component {
  // props: {
  //   dataURL: string;
  //   analyticsCategory: string;
  //   onSave: () => void;
  // };
  static contextTypes = {
    router: PropTypes.object,
  };
  componentWillMount() {
    if (!this.props.dataURL || this.props.dataURL === '' || !isIOS) {
      this.props.history.replace('/');
    }
    $('.footer').hide();
  }
  componentWillUnmount() {
    $('.footer').show();
  }
  save = (e) => {
    e.preventDefault();
    this.props.onSave && this.props.onSave();
    this.refs.form.submit();
  };
  render() {
    return (
      <Wrap>
        <HelpText />
        <ButtonWrap>
          <Button onClick={this.save}>
            <ButtonText>OK, Got it!</ButtonText>
          </Button>
        </ButtonWrap>
        <form ref="form" method="POST" action="/api/download">
          <input type="hidden" name="imageData" value={this.props.dataURL} />
          <input type="hidden" name="filename" value="welcome-mat.png" />
        </form>
      </Wrap>
    );
  }
}

export default withRouter(IOSHelp);
