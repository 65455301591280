import React from 'react';
import pageData from './page-data';
import Header from '../header';
import { Route, Redirect, Switch } from 'react-router-dom';
import find from 'lodash.find';
import { createGlobalStyle } from 'styled-components';

import bgImage from '../images/bg-pattern.jpg';
const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${bgImage});
    background-repeat: repeat;
    padding-bottom: 80px;
  }
`;

export default (props) => {
  let Routes = pageData.map((page) => {
    if (page.component) {
      return (
        <Route
          key={page.id}
          path={`${page.route}`}
          exact={true}
          render={(props) =>
            props.location.pathname.substr(-1) === '/' ? (
              <Redirect to={props.location.pathname.replace(/\/+$/, '')} />
            ) : (
              <page.component {...props} />
            )
          }
        />
      );
    } else {
      return null;
    }
  });
  let backPageID = null;
  let backPage = null;
  try {
    backPageID = find(pageData, {
      route: props.location.pathname.replace(/\/+$/, ''),
    }).backPage;
    backPage = find(pageData, { id: backPageID });
  } catch (e) {}

  return (
    <div>
      <GlobalStyle />
      <Header backPage={backPage || '/'} />
      <Switch>
        {Routes}
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
};
