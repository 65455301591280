import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'winter-warm': {
    ...baseStyle,
    name: 'Winter Warm',
    thumbnail: '/images/mats/mat_winter_warm-thumb.jpg',
    bgImage: '/images/mats/mat_winter_warm-bg.jpg',
    overlayImage: '/images/mats/mat_winter_warm-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'modesto-text, Georgia, serif',
      spacing: 0.25,
      pos: { x: 0.5, y: 758 / 1056 },
      maxWidth: 646 / 1280,
      fontColor: '#000',
      fontSize: 35 / 1280,
      // fontWeight: '800',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: 938 / 1056,
      },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: {
        x: 0.5,
        y: (938 + 48) / 1056,
      },
    },
  },
};

export default mats;
