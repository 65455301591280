import img1 from '../images/hustlegraphics/Hustlegraphics-1.png';
import img10 from '../images/hustlegraphics/Hustlegraphics-10.png';
import img11 from '../images/hustlegraphics/Hustlegraphics-11.png';
import img12 from '../images/hustlegraphics/Hustlegraphics-12.png';
import img2 from '../images/hustlegraphics/Hustlegraphics-2.png';
import img3 from '../images/hustlegraphics/Hustlegraphics-3.png';
import img4 from '../images/hustlegraphics/Hustlegraphics-4.png';
import img5 from '../images/hustlegraphics/Hustlegraphics-5.png';
import img6 from '../images/hustlegraphics/Hustlegraphics-6.png';
import img7 from '../images/hustlegraphics/Hustlegraphics-7.png';
import img8 from '../images/hustlegraphics/Hustlegraphics-8.png';
import img9 from '../images/hustlegraphics/Hustlegraphics-9.png';
import imgDefault from '../images/hustlegraphics/Hustlegraphics-default.png';
import imgDefault2 from '../images/hustlegraphics/Hustlegraphics-default2.png';
import imgDefault3 from '../images/hustlegraphics/Hustlegraphics-default3.png';
import {
  COFFEE,
  COMMERCIAL_LUXURY,
  EXPERIENCE,
  HOMES_BOUGHT,
  HOMES_SOLD,
  LUNCHES,
  MILES,
  MIRACLE_HOMES,
  NEIGHBORHOODS,
  OPEN_HOUSES,
  SONG,
  TREAT,
} from '../reducers/hustlegraphics';
import AnswerNumber from './answer-number';

const GENERIC_ANIMATION_PATH = '/hustlegraphic_animations/generic/index.html';

export const DEFAULT_HUSTLES = [imgDefault, imgDefault2, imgDefault3];

export type Question = {
  id: string;
  questionText: string;
  animationPath: string;
  animationName: string;
  component: any;
  analyticsLabel: string;
  image: string;
  isCustom?: boolean;
};

const data: Array<Question> = [
  {
    id: HOMES_SOLD,
    questionText: 'How many high-fives did you share with happy clients?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'homes_sold',
    component: AnswerNumber,
    analyticsLabel: 'Homes Sold',
    image: img1,
  },
  {
    id: HOMES_BOUGHT,
    questionText: 'How many happy homeowner pictures did you post?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'homes_bought',
    component: AnswerNumber,
    analyticsLabel: 'Homes Bought',
    image: img2,
  },
  {
    id: COMMERCIAL_LUXURY,
    questionText: 'How many glowing 5-star reviews did you receive?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'luxury_commercial',
    component: AnswerNumber,
    analyticsLabel: 'Commercial.Collection Properties',
    image: img3,
  },
  {
    id: NEIGHBORHOODS,
    questionText: 'For how many years have you been making real estate magic?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'neighborhoods',
    component: AnswerNumber,
    analyticsLabel: 'Neighborhoods',
    image: img4,
  },
  {
    id: EXPERIENCE,
    questionText:
      'How many referrals have you received from delighted clients?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'experience',
    component: AnswerNumber,
    analyticsLabel: 'Years of Experience',
    image: img5,
  },
  {
    id: OPEN_HOUSES,
    questionText: 'How many welcoming open houses have you hosted?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'open_houses',
    component: AnswerNumber,
    analyticsLabel: 'Open Houses',
    image: img6,
  },
  {
    id: SONG,
    questionText: 'How many hours have you spent giving back to the community?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'song',
    component: AnswerNumber,
    analyticsLabel: '# Hustle Song',
    image: img7,
  },
  {
    id: MILES,
    questionText: 'How many miles have you traveled for a deal?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'miles',
    component: AnswerNumber,
    analyticsLabel: 'Miles Driven',
    image: img8,
  },
  {
    id: LUNCHES,
    questionText:
      'How many days did it take for you to make your fastest sale?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'lunches',
    component: AnswerNumber,
    analyticsLabel: 'Car Lunches',
    image: img9,
    isCustom: true,
  },
  {
    id: MIRACLE_HOMES,
    questionText: 'How many offers have you prepared with care?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'miracle_homes',
    component: AnswerNumber,
    analyticsLabel: 'Miracle Homes Sold',
    image: img10,
  },
  {
    id: TREAT,
    questionText: 'How many housewarming gifts have you delivered?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'treat',
    component: AnswerNumber,
    analyticsLabel: 'Treat',
    image: img11,
  },
  {
    id: COFFEE,
    questionText: 'How many personalized neighborhood tours have you given?',
    animationPath: GENERIC_ANIMATION_PATH,
    animationName: 'coffee',
    component: AnswerNumber,
    analyticsLabel: 'Coffee',
    image: img12,
  },
];

export default data;
