const Lockup: React.FunctionComponent<React.SVGProps<SVGAElement>> = ({
  className,
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 441 42"
  >
    <title>RE/MAX Hustle</title>
    <path
      d="M29.62786,14.6241v-.21818a11.97119,11.97119,0,0,0-3.42058-8.9396C23.67061,3.0478,19.875,1.76938,15.23049,1.76938H.033V40.23089l8.61782,0V27.69665h4.93989l7.37349,12.53421h9.86937L22.266,25.97691A11.602,11.602,0,0,0,29.62786,14.6241Zm-8.67486.16337v.05443c0,3.48447-2.37426,5.56485-6.3512,5.56485h-5.951V9.22261h5.7796C18.69744,9.22261,20.953,11.147,20.953,14.78747ZM151.294,1.55109,136.82962,40.23078h8.69763l2.915-8.2834,13.53877,0,2.97225,8.28343,8.92531,0L159.41433,1.55109Zm8.06125,23.10636h-8.28685l4.17047-11.77361Zm39.68939-4.0932,11.52831-18.795h-9.589l-6.81607,12.19119L187.46456,1.76927h-9.816l11.41207,18.903L177.13268,40.23076h9.5876l7.27441-12.90307,7.21786,12.90307,9.81667,0ZM47.36977,32.7776l0-8.1872H62.80252V17.13717H47.36974V9.22259l17.5476,0V1.76935H38.80917l0,38.46151,16.19637,0L58.94,32.7776ZM121.22741,1.7693l-8.70525,12.46362L103.704,1.76933h-.09939L94.75345,18.53269l0,21.69812h8.38914l0-23.81621,9.06593,11.80292.04754.079h.36281l9.17059-11.99041V40.23081l8.44615,0,0-38.46148Z"
      fill="#dc1c2e"
    />
    <polygon
      points="84.146 1.769 63.843 40.231 74.309 40.231 94.617 1.769 84.146 1.769"
      fill="#004e9b"
    />
    <path
      d="M215.18114.44454a2.8204,2.8204,0,0,1,2.83938,2.82973A2.85808,2.85808,0,1,1,215.169.44454ZM215.169.88523a2.35467,2.35467,0,0,0-2.31159,2.389,2.31928,2.31928,0,1,0,4.636.01155A2.34973,2.34973,0,0,0,215.18114.88523Zm-.54058,4.03582h-.51178V1.76462a5.66431,5.66431,0,0,1,.90951-.07561,1.73469,1.73469,0,0,1,1.003.23894.744.744,0,0,1,.29593.652.78788.78788,0,0,1-.63032.76862v.02566c.28439.05057.47534.29975.53933.76667a2.56145,2.56145,0,0,0,.20628.78015h-.54a2.30647,2.30647,0,0,1-.2184-.80573.59915.59915,0,0,0-.69366-.55279h-.35994Zm0-1.74857h.37215c.42526,0,.78456-.15118.78456-.53933,0-.278-.20557-.554-.78456-.554a2.74215,2.74215,0,0,0-.37215.025Z"
      fill="#dc1c2e"
    />
    <path d="M84.23294,1.73088l-20.4,38.5h10.5l20.3-38.5Z" fill="#004e9b" />
    <path
      d="M256.42918,40.23073V24.70353H240.735v15.5272H232.227V1.55089H240.735V16.85743h15.69414V1.55089h8.50807V40.23073Z"
      fill="#004e9b"
    />
    <path
      d="M288.40939,40.839c-10.38856,0-16.74471-5.80256-16.74471-17.185V1.55089h8.51V23.43325c0,6.29855,3.14976,9.55864,8.344,9.55864s8.344-3.14976,8.344-9.28331V1.55089h8.51v21.8267C305.3728,35.09206,298.796,40.839,288.40939,40.839Z"
      fill="#004e9b"
    />
    <path
      d="M325.33813,40.78331a24.60921,24.60921,0,0,1-16.41079-6.1892l5.02829-6.02224c3.47978,2.87345,7.12749,4.69633,11.54848,4.69633,3.47978,0,5.58092-1.38156,5.58092-3.64673v-.11033c0-2.15485-1.32591-3.26009-7.79141-4.918-7.79142-1.98886-12.81972-4.14468-12.81972-11.82479v-.11033c0-7.01813,5.6356-11.65979,13.53832-11.65979a22.38922,22.38922,0,0,1,14.36628,4.918l-4.421,6.40986c-3.4251-2.37551-6.79552-3.81272-10.05659-3.81272-3.25912,0-4.97362,1.49189-4.97362,3.37042v.11131c0,2.54148,1.65787,3.37042,8.344,5.083,7.84609,2.04451,12.26709,4.86231,12.26709,11.60413v.11033C339.53843,36.47362,333.68217,40.78331,325.33813,40.78331Z"
      fill="#004e9b"
    />
    <path
      d="M362.20379,9.398V40.23068h-8.51V9.398H341.92463V1.55089h32.0483V9.398Z"
      fill="#004e9b"
    />
    <path
      d="M379.26229,40.23068V1.55089h8.51v30.944h19.28327v7.73576Z"
      fill="#004e9b"
    />
    <path
      d="M411.51589,40.23068V1.55089h29.17583V9.12165H419.96928v7.84609h18.23465v7.56978H419.96928V32.6609h20.99777v7.56978Z"
      fill="#004e9b"
    />
  </svg>
);

export default Lockup;
