import { store } from '../app';
import Analytics, { GA_CATEGORY_HUSTLEGRAPHIC } from '../analytics';
import IOSHelp from '../lib/components/ios-help';
import React from 'react';
import { connect, Provider } from 'react-redux';

function mapStateToProps(state) {
  return {
    dataURL: state.hustlegraphicImage.dataURL,
  };
}

const ReduxContainer = connect(mapStateToProps, {})(IOSHelp);

function logSave() {
  Analytics.event({
    category: GA_CATEGORY_HUSTLEGRAPHIC,
    action: 'Share Complete',
    label: 'Download iOS',
  });
}

export default (props) => (
  <Provider store={store}>
    <ReduxContainer {...props} onSave={logSave} />
  </Provider>
);
