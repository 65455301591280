import { withRouter } from 'react-router';
import styled from 'styled-components';

const Terms = styled((p) => (
  <div {...p}>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Terms of Use for RE/MAX Hustle</span>
      </strong>
    </p>
    <p>Last Modified: July 29, 2024</p>
    <p style={{ textAlign: 'justify' }}>
      Welcome to RE/MAX Hustle (the “<strong>Site</strong>”). This page
      describes the terms and conditions that govern your use of the Site.
      &nbsp;Please see RE/MAX’s{' '}
      <a
        href="https://www.remax.com/privacy-notice"
        target="_blank"
        style={{ textDecoration: 'none' }}
        rel="noreferrer"
      >
        <span style={{ color: '#00B0F0' }}>Privacy Notice</span>
      </a>{' '}
      applicable to users in the United States and this RE/MAX{' '}
      <a
        href="https://www.remax.ca/privacy-notice"
        target="_blank"
        style={{ textDecoration: 'none' }}
        rel="noreferrer"
      >
        <span style={{ color: '#00B0F0' }}>Privacy Notice</span>
      </a>{' '}
      applicable to users in Canada for information on RE/MAX’s data collection
      and information handling practices.&nbsp;
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Acceptance of the Terms of Use</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      As of the Last Modified date above, the Site is publicly accessible,
      although it is primarily intended for use by RE/MAX broker/owner
      franchisees and their network of independent real estate sales
      professionals (“
      <strong>User</strong>” or “<strong>you</strong>”) and is made available to
      Users by RE/MAX, LLC (“<strong>RE/MAX, LLC</strong>” or “
      <strong>we</strong>” or “<strong>us</strong>”) subject to Your acceptance
      without modification of all of terms and conditions (“
      <strong>Terms of Use</strong>”) set forth herein. These Terms of Use are
      entered into by and between you and RE/MAX, LLC and govern your access to
      and use of the Site, including any content, information, features, and
      functionality available on or through the Site. Please read these Terms of
      Use carefully before using the Site. &nbsp;
    </p>
    <p style={{ textAlign: 'justify' }}>
      <span>
        BY USING THE SITE, YOU (A) REPRESENT THAT YOU ARE LOCATED IN THE UNITED
        STATES OR CANADA; (B) ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND
        UNDERSTAND THESE TERMS OF USE; (C) REPRESENT THAT YOU ARE 18 YEARS OF
        AGE OR OLDER; AND (D) ACCEPT THESE TERMS OF USE AND AGREE THAT THEY ARE
        A BINDING AGREEMENT BETWEEN YOU AND US. IF YOU DO NOT AGREE TO THESE
        TERMS, DO NOT USE THE SITE
      </span>
      <span style={{ fontSize: 14, color: '#404041' }}>.</span>
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Changes to the Terms of Use</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      We may revise and update these Terms of Use from time to time in our sole
      discretion. All changes are effective immediately when we post them and
      apply to all access to and use of the Site thereafter. &nbsp;Your
      continued use of the Site following the posting of revised Terms of Use
      means that you accept and agree to the changes.&nbsp;
      <span>
        You further waive any right you may have to receive specific notice of
        such changes to these Terms of Use. As a user of the Site, it is your
        responsibility to regularly review these Terms of Use.
      </span>
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>The Site and its Content</span>
      </strong>
    </p>
    <p
      style={{
        marginBottom: '7.5pt',
        textAlign: 'justify',
        background: 'white',
      }}
    >
      <span style={{ color: 'black' }}>
        The Site is a resource, and use of the Site is voluntary.&nbsp;
      </span>
      <span style={{ color: 'black' }}>
        The Site is designed to enable you to create marketing content and other
        advertising materials to promote your independently owned and operated
        RE/MAX real estate office and the high-quality real estate services you
        provide.
      </span>
      <span style={{ color: 'black' }}>
        &nbsp;RE/MAX does not provide professional advice or endorsements based
        on information or content included on the Site.&nbsp;
      </span>
    </p>
    <p style={{ textAlign: 'justify' }}>
      RE/MAX, LLC reserves the right to access and use the Site, and any content
      and information contained therein, to the extent necessary to run the Site
      and make it available to You and others, to protect the Site, to improve
      the Site, and to otherwise use the Site and its content for a legitimate
      business purpose.
    </p>
    <p
      style={{
        marginBottom: '12.0pt',
        textAlign: 'justify',
        background: 'white',
      }}
    >
      <span style={{ color: 'black' }}>
        The layout, formatting, and features of and access privileges for the
        Site shall be as determined or specified by us in our sole discretion.
        &nbsp;We shall also have the sole right to modify, upgrade, and change
        the Site, including through the removal or modification of any content
        (including, as defined later in these Terms of Use, RE/MAX Marketing
        Content and User Generated Content) on the Site. &nbsp;
      </span>
      <span style={{ color: 'black' }}>
        RE/MAX assumes no responsibility or liability for any actions or
        representations of any subject matter contained on, or created using the
        Site, nor can RE/MAX, LLC guarantee or assume liability for the
        accuracy, completeness or usefulness of any information contained on the
        Site or linked to the Site.&nbsp;
      </span>
      <span style={{ color: 'black' }}>
        We will not be liable if for any reason all or any part of the Site or
        its content is unavailable at any time or for any period. &nbsp;From
        time&nbsp;
      </span>
      <span style={{ color: 'black' }}>
        to time, we may restrict access to some parts of the Site, or all of the
        Site, to users, in our sole discretion. RE/MAX, LLC may terminate or
        restrict your access to and use of the Site, or any part of the Site,
        for any reason, with or without notice.
      </span>
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Third Party Services</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      The Site may contain links to third party websites, (“
      <strong>Third Party Sites</strong>”) such a
      <span>
        s service providers who have a relationship with RE/MAX, LLC. These
        Terms of Use only apply when you are on the Site. Different terms,
        conditions, and privacy notices will apply when you access or use
        Third-Party Sites, so you should read the applicable terms of use and
        privacy notice before using Third-Party Sites or disclosing any personal
        information. When you access Third Party Sites on the internet, you do
        so at your own risk. Third-Party Sites are not under our control, and
        you acknowledge that we are not responsible or liable for the content,
        functions, accuracy, legality, appropriateness or any other aspect of
        such websites or resources. The inclusion of any such link does not
        imply our endorsement or any association between us and their operators.
        You further acknowledge and agree that we shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with the use of or reliance on any such
        content, goods or services available on or through any such Third-Party
        Site.
      </span>
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>License Grant and Restrictions</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      <span>
        Subject to your compliance with these Terms of Use, we hereby grant to
        you a limited, revocable, non-transferrable, non-exclusive and
        non-sublicensable license to use of the Site. All rights to use the Site
        are granted on the condition that such rights are forfeited if you fail
        to comply with these Terms of Use. These Terms of Use provide only a
        license and not an assignment or sale. We transfer no ownership, or
        intellectual property interest, or title in and to the Site to you or
        anyone else. Further, we reserve all rights not expressly granted by
        these Terms of Use. Accordingly, with respect to your use of the Site,
        you may not modify, translate, decompile, reverse engineer, create
        derivative work(s) of, copy, distribute, disassemble, broadcast, film,
        transmit, display, publish, remove, or alter any proprietary notices or
        labels, license, sublicense, permit use by any (other) person or entity,
        transfer, sell, mirror, frame, exploit, rent, lease, private label,
        grant a security interest in, or otherwise use the Site in any manner
        not expressly permitted herein. In addition, you shall not enter into
        any contractual relationship or other legally binding obligation with
        any third party or person which shall have the purpose or effect of
        encumbering RE/MAX, LLC or the Site.
      </span>
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>User Obligations</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      <span>
        Each User is responsible for complying with these Terms of Use and
        ensuring that its use of the Site is in compliance with all applicable
        laws and regulations of their applicable jurisdiction/ You further&nbsp;
      </span>
      agree to abide by all applicable local, state, national, and international
      laws and regulations when using the Site. &nbsp;You also acknowledge and
      agree that use of the Site is solely at your own risk.
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Use of RE/MAX Marketing Content</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      As part of the Site, we may make available for use certain marketing
      content, including videos, templates, marketing materials, and imagery
      (the “<strong>RE/MAX Marketing Content</strong>”). The RE/MAX Marketing
      Content may only be used or downloaded by RE/MAX sales associates and
      RE/MAX broker/owners (“<strong>RE/MAX Affiliates</strong>”). If you are
      not a RE/MAX Affiliate, then you are not authorized or permitted to use
      the RE/MAX Marketing Content in any way. You hereby acknowledge and agree
      that you will observe any limits on the use of such RE/MAX Marketing
      Content that are required by us, by law, or by anyone with an interest in
      such RE/MAX Marketing Content.&nbsp;
    </p>
    <p>
      <span
        style={{
          color: '#1D1C1D',
          background: 'white',
        }}
      >
        The following terms apply to all RE/MAX Affiliates who use the RE/MAX
        Marketing Content
      </span>
    </p>
    <ul style={{ listStyleType: 'disc' }}>
      <li>
        The use of any RE/MAX Marketing Content may be discontinued at any time
        at our sole option, and you agree to take any action required to
        discontinue such use when required.&nbsp;
      </li>
      <li>
        You further agree to (a) use the RE/MAX Marketing Content in a manner
        compliant with the RE/MAX Brand Identity Trademark and Graphic Standards
        manual; (b) use the RE/MAX Marketing Content only for promoting and
        advertising your RE/MAX real estate activities and not in any way that
        harms, disparages, detracts from, or devalues RE/MAX, LLC or its
        affiliates; (c) comply with any expiration date(s) for such RE/MAX
        Marketing Content, regardless of how such dates are communicated; (d)
        upon an expiration date that applies to particular RE/MAX Marketing
        Content, immediately delete, destroy, and otherwise stop any and all
        usage of, any products or materials, even ones that you have created or
        purchased, that incorporate or use any portion of such expired RE/MAX
        Marketing Content; and (e) upon any notification from us requiring that
        you do so, immediately delete, destroy, and otherwise stop any and all
        usage of, any products or materials, even ones that you have created or
        purchased, that incorporate or use any portion of the RE/MAX Marketing
        Content that is the subject of such notification.&nbsp;
      </li>
      <li>
        You also hereby agree that if you modify the RE/MAX Marketing Content in
        any manner, including but not limited to adding your business contact
        &nbsp;information, you do so at your own risk and you assume any and all
        liability for (i) any claims that such usage violates applicable rules,
        laws or regulations or is otherwise improper, including due to copyright
        infringement or a misleading or false advertisement, and (ii) any and
        all costs incurred by RE/MAX, LLC arising from any claims relating to
        your usage of the RE/MAX Marketing Content, including court costs and
        attorney fees.
      </li>
    </ul>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Intellectual Property Rights</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      The Site and its features, contents and functionality (including but not
      limited to all software, displays, capabilities, and the design,
      selection, and arrangement thereof) are owned by RE/MAX, LLC, its
      licensors, or other providers of such features and functionality and are
      protected by United States and international copyright, trademark, patent,
      trade secret, and other intellectual property or proprietary rights laws.
    </p>
    <p style={{ textAlign: 'justify' }}>
      If you modify the features and functionality or otherwise use or provide
      any other person with access to any part of the Site in breach of the
      Terms of Use, your right to use the Site will stop immediately. Any use of
      the Site not expressly permitted by these Terms of Use is a breach of
      these Terms of Use and may violate copyright, trademark, and other laws.
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Trademarks</span>
      </strong>
    </p>
    <p>
      RE/MAX’s name, logo, and all related names, logos, product and service
      names, designs, and slogans are trademarks of RE/MAX or its affiliates or
      licensors, which may be registered in the United States or other
      jurisdictions. You agree that any use of the RE/MAX trademarks in
      connection with the Site will be compliant with the RE/MAX Brand Identity
      Trademark and Graphic Standards manual.&nbsp;
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Copyright Complaints</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      It is the policy of RE/MAX to respond to reasonable notices alleging
      copyright infringement. If you believe that your work has been copied in a
      way that constitutes copyright infringement, you may submit a notification
      by providing our Copyright Designated Agent with the following information
      in writing:
    </p>
    <ul style={{ listStyleType: 'disc' }}>
      <li>
        A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
      </li>
      <li>
        Identification of the copyrighted work claimed to have been infringed;
      </li>
      <li>
        Identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and that is to be removed or access
        to which is to be disabled and information reasonably sufficient to
        permit us to locate the material;
      </li>
      <li>
        Information reasonably sufficient to permit us to contact you, such as
        an address, telephone number, and e-mail address;
      </li>
      <li>
        A statement that you have a good faith belief that use of the material
        in the manner complained of is not authorized by the copyright owner,
        its agent, or the law; and
      </li>
      <li>
        A statement that the information in the notification is accurate, and
        under penalty of perjury, that you are authorized to act on behalf of
        the owner of an exclusive right that is allegedly infringed.
      </li>
    </ul>
    <p>
      Notices of claimed copyright infringement and counter-notices should be
      sent to our attention either by email or regular mail, at the following
      addresses:
    </p>
    <p style={{ marginLeft: '.5in' }}>
      By email (preferred): &nbsp;legal@remax.com
    </p>
    <p style={{ marginLeft: '.5in' }}>
      By mail: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp;&nbsp;Copyright Designated Agent
    </p>
    <p style={{ marginLeft: '1.5in', textIndent: '.5in' }}>Legal Department</p>
    <p style={{ marginLeft: '1.5in', textIndent: '.5in' }}>RE/MAX, LLC</p>
    <p style={{ marginLeft: '1.5in', textIndent: '.5in' }}>
      5075 South Syracuse Street
    </p>
    <p style={{ marginLeft: '1.5in', textIndent: '.5in' }}>Denver, CO 80237</p>
    <p style={{ textAlign: 'justify' }}>
      For both email and postal mail notices, please include "Notice of
      Infringement" in the subject line. If a notice is incomplete, RE/MAX, LLC
      is under no obligation to act.
    </p>
    <p style={{ textAlign: 'justify' }}>
      If RE/MAX, LLC removes or restricts access to content in response to a
      copyright complaint, RE/MAX, LLC will make a good faith effort to contact
      the alleged infringer with information concerning the removal or
      restriction of access, including a copy of the complaint. If the alleged
      infringer believes its content was removed in error, then it can submit a
      counter-notification to RE/MAX, LLC requesting that the removed content be
      reinstated.
    </p>
    <p style={{ textAlign: 'justify' }}>
      RE/MAX, LLC has a policy of terminating repeat infringers. If RE/MAX, LLC
      receives multiple copyright complaints pertaining to an alleged infringer,
      RE/MAX, LLC may terminate or restrict the alleged infringer’s access to
      the Site or take steps to limit or prohibit that person’s content from
      appearing on the Site.
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Prohibited Uses</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      You may use the Site only for lawful purposes and in accordance with these
      Terms of Use. You agree not to use the Site:
    </p>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        In any way that violates any applicable federal, state, local, or
        international law or regulation (including, without limitation, any laws
        regarding: (1) the export of data or software to and from the US or
        other countries, (2) the protection of personal data of individuals, (3)
        advertising, including the Fair Housing Act or any state or local laws
        and regulations, and (4) marketing and cell phone communications,
        including the U.S. Telephone Consumer Protection Act).&nbsp;
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        For the purpose of exploiting, harming, or attempting to exploit or harm
        minors in any way by asking for personally identifiable information, or
        otherwise.
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To transmit, or procure the sending of, any advertising or promotional
        material that would be considered improper, inappropriate, or burdensome
        to others such as “spam,” or any other similar solicitation, including
        unwanted or unsolicited bulk email, postings, contact requests, SMS
        (text messages), or instant messages. &nbsp;This does not include
        marketing communications in the ordinary course of business and
        consistent with an individual’s expectations of their interaction with a
        broker, agent, or other brokerage representative.&nbsp;
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To impersonate or attempt to impersonate another user, or any other
        person or entity (including, without limitation, by using email
        addresses associated with any of the foregoing).
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To engage in any other conduct that restricts or inhibits anyone’s use
        or enjoyment of the Site, or which, as determined by us, may harm
        RE/MAX, LLC or users of the Site, or expose them to liability.
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To upload invalid data, worms, viruses, or other software agent to the
        Site.
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To use any software that intercepts, "mines," or otherwise collects
        information through or from the Site.
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To use the information provided by Company through the Site in making
        any loan-related decisions.
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To access or use the Site to develop competitive products or services.
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li style={{ textAlign: 'justify' }}>
        To remove or modify any copyright or other intellectual property notices
        that appear in the Site.
      </li>
    </ul>
    <p>Additionally, you agree not to:</p>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li>
        Circumvent any restrictions on access to or availability of the Site.
      </li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li>Engage in activity that is harmful to you, the Site, or others.</li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li>Infringe upon the rights of others.</li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li>Engage in activity that violates the privacy of others.</li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li>Help others break these rules.</li>
    </ul>
    <ul style={{ marginTop: '0in' }} itemType="disc">
      <li>
        Otherwise attempt to interfere with the proper working of the Site.
      </li>
    </ul>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Disclaimer of Warranties</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      YOUR USE OF THE SITE AND ITS CONTENT IS AT YOUR OWN RISK. THE SITE IS
      PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF
      ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER RE/MAX, LLC NOR ANY PERSON
      ASSOCIATED WITH RE/MAX, LLC MAKES ANY WARRANTY OR REPRESENTATION WITH
      RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
      AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER RE/MAX,
      LLC NOR ANYONE ASSOCIATED WITH RE/MAX, LLC REPRESENTS OR WARRANTS THAT THE
      SITE WILL OPERATE UNINTERRUPTED OR IN A MANNER THAT WILL MEET YOUR
      PARTICULAR REQUIREMENTS AND/OR NEEDS. &nbsp;TO THE FULLEST EXTENT PROVIDED
      BY LAW, RE/MAX, LLC HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
      EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
      ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
      PARTICULAR PURPOSE. &nbsp;RE/MAX, LLC ALSO DISCLAIMS ANY RESPONSIBILITY
      FOR THE CONTENT, THE MATERIALS, THE ACCURACY OF THE INFORMATION, AND/OR
      THE QUALITY OF THE INFORMATION PROVIDED BY OR AVAILABLE THROUGH THE SITE.
    </p>
    <p style={{ textAlign: 'justify' }}>
      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Limitation on Liability</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL RE/MAX, LLC, ITS
      SHAREHOLDERS, DIRECTORS, AFFILIATES, OFFICERS, AGENTS AND EMPLOYEES,
      LICENSORS, SERVICE PROVIDERS, AND ALL THIRD PARTIES THAT PROVIDE CONTENT,
      INFORMATION OR SERVICES TO THE SITE BE LIABLE FOR DAMAGES OF ANY KIND,
      UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
      INABILITY TO USE, THE SITE, ANY APPLICATIONS LINKED TO IT, ANY CONTENT ON
      THE SITE OR SUCH OTHER APPLICATIONS, INCLUDING ANY DIRECT, INDIRECT,
      SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT
      LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS
      OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS
      OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
      (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF
      FORESEEABLE.&nbsp;
    </p>
    <p style={{ textAlign: 'justify' }}>
      THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
    </p>

    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Indemnification</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      You agree to indemnify and hold harmless RE/MAX, its shareholders,
      directors, affiliates, officers, agents and employees, licensors, service
      providers, and all third parties that provide content, information or
      services to the Site from and against any and all claims, suits, demands,
      proceedings, liabilities, losses, damages, costs and expenses whatsoever,
      including but not limited to reasonable attorney fees and disbursements,
      court costs or arbitration costs, due to, arising out of, or relating to
      your violation of these Terms of Use or your use of the Site, including,
      but not limited to, your contributions of content or information to the
      Site or any use of the Site’s content other than as expressly authorized
      in these Terms of Use.
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Waiver and Severability</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      No waiver by RE/MAX, LLC of any term or condition set out in these Terms
      of Use shall be deemed a further or continuing waiver of such term or
      condition or a waiver of any other term or condition, and any failure of
      RE/MAX, LLC to assert a right or provision under these Terms of Use shall
      not constitute a waiver of such right or provision.
    </p>
    <p style={{ textAlign: 'justify' }}>
      If any provision of these Terms of Use is held by a court or other
      tribunal of competent jurisdiction to be invalid, illegal, or
      unenforceable for any reason, such provision shall be eliminated or
      limited to the minimum extent such that the remaining provisions of the
      Terms of Use will continue in full force and effect.&nbsp;
    </p>

    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Governing Law</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      All matters relating to the Site and these Terms of Use, and any dispute
      or claim arising therefrom or related thereto (in each case, including
      non-contractual disputes or claims), shall be governed by and construed in
      accordance with the internal laws of the State of Colorado, excluding any
      principles or rules of law that may direct the application of the law of
      another state.&nbsp;
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>
          Arbitration and Waiver of Class Arbitration
        </span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      Any dispute, controversy or claim arising out of, relating to or in
      connection with these Terms of Use, including the breach, termination, or
      validity thereof, shall be finally resolved by arbitration administered by
      the American Arbitration Association under its Commercial Arbitration
      Rules. The number of arbitrators shall be one (1), and the place of
      arbitration shall be Denver, Colorado, United States. The arbitration
      shall be held, and the award shall be rendered, in English. The tribunal
      shall have the power to rule on any challenge to its own jurisdiction or
      to the validity or enforceability of any portion of the agreement to
      arbitrate.
    </p>
    <p style={{ textAlign: 'justify' }}>
      The parties agree to arbitrate solely on an individual basis, and that
      this agreement does not permit class arbitration, or any claims brought as
      a plaintiff or class member in any class or representative arbitration
      proceeding. The arbitral tribunal may not consolidate more than one
      person's claims and may not otherwise preside over any form of a
      representative or class proceeding. Notwithstanding the tribunal's power
      to rule on its own jurisdiction and the validity or enforceability of the
      agreement to arbitrate, the tribunal has no power to rule on the validity
      or enforceability of the agreement to arbitrate solely on an individual
      basis.&nbsp;
    </p>
    <p style={{ textAlign: 'justify' }}>
      In the event the prohibition on class arbitration is deemed invalid or
      unenforceable, then the remaining portions of the arbitration agreement
      will remain in force.
    </p>
    <p style={{ textAlign: 'justify' }}>
      <strong>
        <span style={{ fontSize: 19 }}>Feedback</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      <span style={{ color: '#404041' }}>
        If you provide input or suggestions regarding the Site, including
        related to RE/MAX Marketing Content or RE/MAX, LLC (collectively the "
        <strong>Feedback</strong>"), you hereby grant us an unrestricted,
        perpetual, irrevocable, non-exclusive, fully paid, royalty free right to
        use the Feedback for any purpose and in any manner we, in our sole
        discretion, deem proper.
      </span>
    </p>
    <p style={{ textAlign: 'justify' }}>
      <strong>
        <span style={{ fontSize: 19 }}>Entire Agreement</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      The Terms of Use constitute the sole and entire agreement between you and
      RE/MAX, LLC regarding the Site and supersede all prior and contemporaneous
      understandings, agreements, representations, and warranties, both written
      and oral, regarding the Site.&nbsp;
    </p>
    <p>
      <strong>
        <span style={{ fontSize: 19 }}>Contact Information</span>
      </strong>
    </p>
    <p style={{ textAlign: 'justify' }}>
      The Site is operated by RE/MAX, LLC at 5075 South Syracuse Street; Denver,
      CO 80237.
    </p>
    <p style={{ textAlign: 'justify' }}>
      If You have any feedback, comments, requests for technical support, and
      other communications relating to the Site, please email us at:{' '}
      <a href="mailto:productsupport@remax.net">productsupport@remax.net</a>
    </p>

    <p style={{ textAlign: 'justify' }}>
      <span style={{ color: '#404041' }}>
        Any rights not expressly granted herein are reserved by RE/MAX, LLC.
      </span>
    </p>
  </div>
))`
  font-family: 'Calibri', 'Arial';
  padding: 40px;
  ul {
    padding: 0 20px;
  }
`;
const TermsWrapper = () => {
  return <Terms />;
};
export default withRouter(TermsWrapper);
