import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'family-wh': {
    ...baseStyle,
    name: 'Family WH',
    thumbnail: '/images/mats/mat_004_wh-thumb.jpg',
    bgImage: '/images/mats/mat_004_wh.jpg',
    overlayImage: '/images/mats/mat_004_wh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'proxima-nova, Georgia, serif',
      spacing: 0.11,
      pos: { x: 0.5, y: 0.6729675 },
      maxWidth: 0.583333333333333,
      fontColor: '#000',
      fontSize: 0.02990740741,
      fontWeight: '800',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.8854575 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.93085 },
    },
  },
  'family-hsh': {
    ...baseStyle,
    name: 'Family HSH',
    thumbnail: '/images/mats/mat_004_hsh-thumb.jpg',
    bgImage: '/images/mats/mat_004_hsh.jpg',
    overlayImage: '/images/mats/mat_004_hsh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'superclarendon, Georgia, serif',
      spacing: 0.02,
      pos: { x: 0.5, y: 0.6953310887 },
      maxWidth: 0.5490740741,
      fontColor: '#000',
      fontSize: 0.0305,
      fontWeight: '600',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.8854575 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.93085 },
    },
  },
};

export default mats;
