import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'generic-wh': {
    ...baseStyle,
    name: 'Generic WH',
    thumbnail: '/images/mats/mat_006_wh-thumb.jpg',
    bgImage: '/images/mats/mat_006_wh.jpg',
    overlayImage: '/images/mats/mat_006_wh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'brandon-grotesque, Futura, sans-serif',
      spacing: 0.09,
      pos: { x: 0.505, y: 0.5973063973 },
      maxWidth: 0.583333333333333,
      fontColor: '#000',
      fontSize: 0.04186111111,
      fontWeight: '600',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.8679575 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.91335 },
    },
  },
  'generic-hsh': {
    ...baseStyle,
    name: 'Generic HSH',
    thumbnail: '/images/mats/mat_006_hsh-thumb.jpg',
    bgImage: '/images/mats/mat_006_hsh.jpg',
    overlayImage: '/images/mats/mat_006_hsh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'freight-micro-pro, Georgia, serif',
      spacing: 0.065,
      fontWeight: '800',
      pos: { x: 0.505, y: 0.6443975 },
      maxWidth: 0.583333333333333,
      fontColor: '#000',
      fontSize: 0.03981481481,
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.8679575 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 0.91335 },
    },
  },
};

export default mats;
