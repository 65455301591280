import MatData from './mat-data-type';

const matBase: MatData = {
  name: '',
  thumbnail: '',
  bgImage: '',
  overlayImage: '',
  buyerName: {
    font: `'Gotham A', 'Gotham B', 'futura-pt', 'futura', 'Arial', sans-serif`,
    fontColor: '#000',
    fontWeight: '600',
    pos: { x: 0.5, y: 0.58 },
    maxWidth: 0.65,
    allCaps: true,
    fontSize: 60,
    spacing: 0.1,
    blur: false,
  },
  agentName: {
    font: `'Gotham A', 'Gotham B', 'futura-pt', 'futura', 'Arial', sans-serif`,
    fontColor: '#292929',
    fontWeight: '800',
    pos: { x: 0.5, y: 0.8877665544 },
    maxWidth: 0.69,
    allCaps: true,
    fontSize: 0.0275,
    spacing: 0.09,
    blur: false,
  },
  officeName: {
    font: 'Superclarendon, Georgia, serif',
    fontColor: '#292929',
    fontWeight: '400',
    pos: { x: 0.5, y: 0.93335578 },
    maxWidth: 0.69,
    allCaps: false,
    fontSize: 0.015138888888889,
    spacing: 0.09,
    blur: false,
  },
};

export default matBase;
