import list from './profanity-list';
import { remove as removeDiacritics } from 'diacritics';

export default function (text) {
  const nonSpaces = /([^\s]+)(\s*)/g;
  let match;
  const words = [];

  while (true) {
    match = nonSpaces.exec(text);
    if (!match) {
      break;
    }
    // use just the word without spaces for matching
    let word = match[1];
    word = removeDiacritics(word);
    word = stripPunctuation(word);
    word = word.toLowerCase();
    words.push({
      word: word,
      index: match.index,
      // use length of full match with spaces included
      // will make sure to strip trailing spaces after excluded word
      length: match[0].length,
    });
  }

  let dirtyWords = words.reduce((accumulator, wordInfo) => {
    for (let i = 0; i < list.length; ++i) {
      if (list[i] === wordInfo.word) {
        return accumulator.concat(wordInfo);
      }
    }
    return accumulator;
  }, []);

  let newText = text;

  let offset = 0;
  for (let i = 0; i < dirtyWords.length; ++i) {
    const wordInfo = dirtyWords[i];
    newText =
      newText.slice(0, wordInfo.index - offset) +
      newText.slice(wordInfo.index - offset + wordInfo.length);
    offset += wordInfo.length;
  }

  return newText;
}

function stripPunctuation(word) {
  const stripped = word.replace(/[^A-Za-z]/g, '');
  return stripped;
}
