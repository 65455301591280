import PropTypes from 'prop-types';
import React from 'react';
import { Provider, connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { store } from '../app';
import { PageContentInner } from '../lib/components/page-content';
import { PageHead } from '../lib/components/page-head';
import '../lib/customizer.css';
import { updateImageLink } from '../reducers/hustlegraphics';
import Hustlegraphic from './hustlegraphic';
import questions from './questions-data';
import { validateAll } from './validate-question';

const PageContentViewHustlegraphic = styled((props) => {
  return (
    <div {...props}>
      <PageContentInner>{props.children}</PageContentInner>
    </div>
  );
})`
  padding-top: ${(props) => props.theme.headerHeight}px; /* .header -> height */
  max-width: 600px;
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

class ViewHustlegraphic extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  state = {};
  constructor(props) {
    super(props);
    if (props.params && props.params.idx && props.params.idx !== props.matID) {
      props.setMatType(Number(props.idx));
    }
  }
  agentChanged = (e) => {
    this.props.setAgentName(e.currentTarget.value);
  };
  officeChanged = (e) => {
    this.props.setOfficeName(e.currentTarget.value);
  };
  textChanged = (e) => {
    this.props.setMatText(e.currentTarget.value);
  };
  componentWillMount() {
    const errors = validateAll({
      info: this.props.info,
      answers: this.props.answers,
    });
    if (errors.length > 0) {
      this.props.history.replace('/hustlegraphic');
    }
  }

  render() {
    return (
      <PageContentViewHustlegraphic>
        <PageHead>Your 12 Months of Hustle</PageHead>
        <Hustlegraphic
          setCanvas={(canvas) => {
            this.setState({ hgCanvas: canvas });
          }}
          info={this.props.info}
          answers={this.props.answers}
          questions={questions}
          updateImageLink={this.props.updateImageLink}
        />
      </PageContentViewHustlegraphic>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const props = {
    ...ownProps,
    ...{
      info: state.hustlegraphics.info,
      answers: state.hustlegraphics.answers,
      hustlegraphicDataURL: state.hustlegraphicImage.dataURL,
    },
  };
  return props;
}

const ReduxContainer = connect(mapStateToProps, {
  updateImageLink,
})(withRouter(ViewHustlegraphic));

export default (props) => (
  <Provider store={store}>
    <ReduxContainer {...props} />
  </Provider>
);
