import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import {
  MatData,
  matData,
  matImage,
  initialMatData,
} from './reducers/welcome-mats';
import { twitter, initialTwitterState, TwitterState } from './reducers/twitter';
import {
  hustlegraphics,
  imageLink as hustlegraphicImage,
  State as HustlegraphicsState,
  initialData as HustlegraphicsInitial,
} from './reducers/hustlegraphics';
import reduxFreeze from 'redux-freeze';
const SET_APP_STATE = 'SET_APP_STATE';

interface Action {
  type: string;
  [x: string]: any;
}

interface AppState {
  fontsReady: boolean;
  [x: string]: any;
}

interface AllStates {
  hustlegraphics: HustlegraphicsState;
  hustlegraphicImage: any;
  matData: MatData;
  matImage: object;
  appState: AppState;
  twitter: TwitterState;
}

const initialState: AllStates = {
  hustlegraphics: HustlegraphicsInitial,
  hustlegraphicImage: {},
  matData: initialMatData,
  matImage: {},
  appState: { fontsReady: false },
  twitter: initialTwitterState,
};
function reducer(state: any, action: Action) {
  let newState = {
    hustlegraphics: hustlegraphics(state.hustlegraphics, action),
    hustlegraphicImage: hustlegraphicImage(state.hustlegraphicImage, action),
    matData: matData(state.matData, action),
    matImage: matImage(state.matImage, action),
    appState: appState(state.appState, action),
    twitter: twitter(state.twitter, action),
  };

  return { ...state, ...newState };
}

function appState(state: object, action: Action) {
  switch (action.type) {
    case SET_APP_STATE: {
      return { ...state, ...action.state };
    }
    default: {
      return state;
    }
  }
}

export function setAppState(newState: object) {
  return {
    type: SET_APP_STATE,
    state: newState,
  };
}

let store: Store;
let middleware = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware.push(reduxFreeze as any);
}
if (typeof document !== 'undefined') {
  const lS_NAMESPACE = 'remax_hustle';
  let savedData: {
    matData?: MatData;
    hustlegraphics?: HustlegraphicsState;
  } = {};
  try {
    savedData = JSON.parse(sessionStorage.getItem(lS_NAMESPACE) || '');
  } catch {}

  let state = {
    ...initialState,
    ...{
      matData: {
        ...initialState.matData,
        ...(savedData.matData || {}),
      },
      hustlegraphics: savedData.hustlegraphics,
    },
  };

  store = createStore(reducer, state as any, applyMiddleware(...middleware));

  store.subscribe(() => {
    let state = store.getState();
    sessionStorage.setItem(
      lS_NAMESPACE,
      JSON.stringify({
        matData: state.matData,
        hustlegraphics: state.hustlegraphics,
      })
    );
  });
} else {
  store = createStore(reducer, applyMiddleware(...middleware));
}

export { store };
