import React from 'react';
import styled from 'styled-components';
import { BackButton } from './lib/components/buttons';
import { Provider, connect } from 'react-redux';
import { store } from './app';
import Link from './lib/components/link';
import Analytics, {
  GA_CATEGORY_EXIT_HOMEPAGE,
  GA_CATEGORY_EXIT_REMAX,
} from './analytics';
import LockupImage from './images/remax-hustle-lockup';

const RemaxLogo = styled((props) => {
  return (
    <a
      href="https://remax.com"
      onClick={() =>
        Analytics.event({
          category: GA_CATEGORY_EXIT_REMAX,
          action: GA_CATEGORY_EXIT_REMAX,
        })
      }
    >
      <img {...props} src="/images/remax-balloon.png" alt="RE/MAX" />
    </a>
  );
})`
  height: ${(props) => props.theme.headerHeight}px;
  width: auto;
  position: absolute;
  top: 0;
  right: ${(props) => props.theme.margin}px;
  ${(props) => props.theme.mq1} {
    right: ${(props) => props.theme.margin2}px;
  }
  ${(props) => props.theme.mq2} {
    right: ${(props) => props.theme.margin3}px;
  }
`;

const logoRatio = 0.43;
const HeaderLockup = styled((props) => {
  return (
    <Link
      {...props}
      to="/"
      onClick={() =>
        Analytics.event({
          category: GA_CATEGORY_EXIT_HOMEPAGE,
          action: GA_CATEGORY_EXIT_HOMEPAGE,
        })
      }
    >
      <LockupImage className="image" />
    </Link>
  );
})`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  padding-top: ${(props) => props.theme.headerHeight * (1 - logoRatio) * 0.5}px;
  .image {
    height: ${(props) => props.theme.headerHeight * logoRatio}px;
    path {
      fill: ${(p) => p.theme.textLight};
    }
  }
`;

const HeaderBackButton = styled(BackButton)`
  margin: 6px ${(props) => props.theme.margin}px;
  ${(props) => props.theme.mq1} {
    margin-left: ${(props) => props.theme.margin2}px;
    margin-left: ${(props) => props.theme.margin2}px;
  }
  ${(props) => props.theme.mq2} {
    margin-left: ${(props) => props.theme.margin3}px;
    margin-left: ${(props) => props.theme.margin3}px;
  }
`;

interface HeaderBaseProps {
  backPage?: { route: string; title: string } | null;
}
const HeaderBase: React.FunctionComponent<HeaderBaseProps> = ({
  backPage,
  ...props
}) => {
  if (backPage) {
    return (
      <div {...props}>
        <HeaderBackButton to={backPage.route}>
          {backPage.title}
        </HeaderBackButton>
        <HeaderLockup />
        <RemaxLogo />
      </div>
    );
  } else {
    return null;
  }
};

HeaderBase.defaultProps = {
  backPage: null,
};

function mapStateToProps(state: any) {
  return {};
}

const ReduxContainer = connect(mapStateToProps, {})(HeaderBase);
const Header = (props: object) => (
  <Provider store={store}>
    <ReduxContainer {...props} />
  </Provider>
);

export default styled(Header)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.textLight};
  height: ${(props) => props.theme.headerHeight}px;
`;
