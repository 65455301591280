import profanityFilter from './profanity-filter';

export default (text, altSlash = false) => {
  if (!text) {
    return '';
  }
  if (altSlash) {
    text = text.replace(/re[/⁄∕／]?max/i, 'RE/MAX');
  } else {
    text = text.replace(/re[/⁄∕／]?max/i, 'RE/MAX');
  }
  return profanityFilter(text);
};
