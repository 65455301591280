// @flow
import omit from 'lodash.omit';
import deepFreeze from 'deep-freeze';

export const COFFEE = 'COFFEE';
export const COMMERCIAL_LUXURY = 'COMMERCIAL_LUXURY';
export const DONATIONS = 'DONATIONS';
export const EXPERIENCE = 'EXPERIENCE';
export const HOMES_BOUGHT = 'HOMES_BOUGHT';
export const HOMES_SOLD = 'HOMES_SOLD';
export const LUNCHES = 'LUNCHES';
export const LUXURY = 'LUXURY';
export const MILES = 'MILES';
export const MIRACLE_HOMES = 'MIRACLE_HOMES';
export const NEIGHBORHOODS = 'NEIGHBORHOODS';
export const OPEN_HOUSES = 'OPEN_HOUSES';
export const SONG = 'SONG';
export const TREAT = 'TREAT';

const SET_ANSWER = 'HUSTLEGRAPHICS_SET_ANSWER';
const SET_INFO = 'HUSTLEGRAPHICS_SET_INFO';
const UPDATE_IMAGE_LINK = 'HUSTLEGRAPHIC_UPDATE_IMAGE_LINK';
const RESET_ANSWERS = 'HUSTLEGRAPHICS_RESET_ANSWERS';

export interface AnswerBase {}
export interface AnswerNumber {
  readonly number: string | number;
}
export interface AnswerSong {
  readonly songTitle: string;
}
export interface AnswerCommercialLuxury {
  readonly type: string;
  readonly number: string | number;
}
export interface AnswerTreat {
  readonly treat: string;
}
export type Answer =
  | AnswerNumber
  | AnswerSong
  | AnswerCommercialLuxury
  | AnswerTreat;
export interface Answers {
  [answerID: string]: Answer;
}
export interface Info {
  readonly agentName: string;
  readonly email: string;
  readonly phoneNumber: string;
  readonly officeName: string;
}
export interface ImageLink {
  readonly dataURL?: string;
}
export interface Action {
  type: string;
  [key: string]: any;
}
export interface State {
  readonly answers: Answers;
  readonly info: Info;
  readonly imageLink: ImageLink;
}

function isNumberAnswer(answer: Answer): answer is AnswerNumber {
  return answer.hasOwnProperty('number');
}

const defaultAnswers: { [idx: string]: Answer } = {
  COMMERCIAL_LUXURY: { type: 'commercial', number: '' },
};

export const initialData: State = deepFreeze({
  answers: defaultAnswers,
  info: {
    agentName: '',
    email: '',
    phoneNumber: '',
    officeName: '',
  },
  imageLink: {},
});

export function hustlegraphics(state: State = initialData, action: Action) {
  let newState = {
    answers: answers(state.answers, action),
    info: info(state.info, action),
    imageLink: imageLink(state.imageLink, action),
  };
  return { ...state, ...newState };
}

function sanitizeNumberAnswer(answer: Answer): Answer {
  if (isNumberAnswer(answer)) {
    let num: string;
    if (typeof answer.number === 'number') {
      num = answer.number.toString();
    } else {
      num = answer.number;
    }
    // remove all digits after decimal
    num = num.split('.')[0];
    // remove all non-digits
    num = num.replace(/[^0-9]/g, '');
    // limit to 6 digits
    num = num.substr(0, 6);
    if (num.length > 1) {
      num = num.replace(/^0+/, '');
    }
    return {
      ...answer,
      number: num,
    };
  }
  return answer;
}

export function imageLink(state: ImageLink = {}, action: Action) {
  let newState;
  switch (action.type) {
    case UPDATE_IMAGE_LINK:
      newState = { dataURL: action.canvas.toDataURL() };
      return { ...state, ...newState };
    case RESET_ANSWERS:
      return {};
    default:
      return state;
  }
}

export function updateImageLink(canvas: HTMLCanvasElement) {
  return {
    type: UPDATE_IMAGE_LINK,
    canvas: canvas,
  };
}

function info(state = initialData.info, action: Action) {
  let newState;
  let newValue;
  switch (action.type) {
    case SET_INFO:
      newValue = action.value;
      if (action.prop === 'phoneNumber') {
        newValue = newValue.replace(/[^0-9]/g, '');
      }
      try {
        newValue = newValue.substr(0, 75);
      } catch (e) {}
      newState = { [action.prop]: newValue };
      return { ...state, ...newState };
    case RESET_ANSWERS:
      return { ...initialData.info };
    default:
      break;
  }
  return state;
}

export function setInfo(prop: string, value: string) {
  return {
    type: SET_INFO,
    prop: prop,
    value: value,
  };
}

function answers(state: { [key: string]: Answer }, action: Action) {
  let newState: { [key: string]: Answer };
  let validAnswer: Answer;
  switch (action.type) {
    case SET_ANSWER:
      if (!action.answer && typeof action.questionID === 'string') {
        newState = omit(state, action.questionID);
        const questionID: string = action.questionID as string;
        if (defaultAnswers[questionID]) {
          newState[questionID] = defaultAnswers[questionID];
        }
      } else {
        validAnswer = sanitizeNumberAnswer(action.answer);
        newState = {
          [action.questionID]: { ...state[action.questionID], ...validAnswer },
        };
        newState = { ...state, ...newState };
      }
      return newState;
    case RESET_ANSWERS:
      return { ...initialData.answers };
    default:
      break;
  }
  return state;
}

export function setAnswer(questionID: string, answer: Answer) {
  return {
    type: SET_ANSWER,
    questionID: questionID,
    answer: answer,
  };
}

export function resetHustlegraphics() {
  return { type: RESET_ANSWERS };
}
