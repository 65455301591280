import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Link from './link';

const ButtonWrap = styled(Link)`
  position: relative;
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  background-color: ${(props) => props.theme.primaryColor};
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  letter-spacing: 0.15em;
  font-size: 14px;
  border-radius: ${(props) => props.theme.borderRadius};
  transition: all 0.1s;
  &:hover,
  &.hover {
    background-color: ${(props) => props.theme.primaryColorLight};
    transition: all 0.1s;
    transform: scale(1.03);
  }
  cursor: pointer;
  vertical-align: top;
  &:focus {
    outline: none;
    &:before {
      content: ' ';
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 2px;
      right: 2px;
      border: 2px solid rgba(255, 255, 255, 0.25);
      border-radius: 2px;
    }
  }
`;

const ButtonWrapDisabled = styled(ButtonWrap)`
  // background-color: #888;
  background-color: ${(props) => props.theme.primaryColor};
  &:hover,
  &.hover {
    background-color: ${(props) => props.theme.primaryColor};
    // background-color: #888;
    transform: none;
  }
  & * {
    opacity: 0.6;
  }
  opacity: 0.8;
  cursor: default;
`;

const ButtonWrapSecondary = styled(ButtonWrap)`
  background-color: ${(props) => props.theme.secondaryColorLight};
  &:hover,
  &.hover {
    background-color: ${(props) => props.theme.secondaryColorLight2};
  }
`;

const FakeRow = styled.div`
  display: table-row;
`;
const FakeCell = styled.div`
  vertical-align: middle;
  display: table-cell;
  padding-right: 10px;
  &:last-child {
    padding-right: 0;
  }
`;
const TableWrap = styled.div`
  display: inline-block;
`;

export const ButtonImg = styled.img`
  height: 40px;
  width: auto;
`;
export const ButtonText = styled.div`
  transform: translateY(+1px);
`;

export interface ButtonProps extends Omit<React.HTMLProps<HTMLElement>, 'as'> {
  secondary?: boolean;
  showHover?: boolean;
  disabled?: boolean;
  to?: string | null;
  onClick?: (e: any) => any;
  href?: string;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  secondary,
  showHover,
  className,
  ...props
}) => {
  let Wrap = ButtonWrap;
  if (props.disabled) {
    props.to = '';
    delete props.href;
    props.onClick = (e) => e.preventDefault();
    Wrap = ButtonWrapDisabled;
  } else if (secondary) {
    Wrap = ButtonWrapSecondary;
  }
  const hoverClass = showHover ? 'hover' : '';
  return (
    <Wrap
      {...props}
      to={props.to ? props.to : ''}
      className={classNames('button', className, hoverClass)}
    >
      <TableWrap className={hoverClass}>
        <FakeRow className={hoverClass}>
          {React.Children.map(props.children, (child, index) => (
            <FakeCell key={index}>{child}</FakeCell>
          ))}
        </FakeRow>
      </TableWrap>
    </Wrap>
  );
};
Button.defaultProps = {
  secondary: false,
  showHover: false,
  disabled: false,
  to: null,
};

export const FullWidthButton = styled(Button)`
  display: block;
  text-align: center;
`;

export const ImageButton = styled((props: ButtonProps) => {
  return (
    <Button className={classNames('stuff', props.className)} {...props}>
      {props.children}
    </Button>
  );
})`
  position: relative;
  width: 40px;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`;

export const ImageButtonImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const BackButtonImg = styled(ButtonImg)`
  height: 24px;
`;

export const BackButton = styled((props: ButtonProps) => {
  return (
    <Button {...props}>
      <BackButtonImg src="/images/back-arrow.png" />
      {props.children}
    </Button>
  );
})<ButtonProps>`
  height: 25px;
  line-height: 24px;
  font-size: 11px;
  letter-spacing: 0.05em;
  font-weight: 300;
  background-color: ${(props) => props.theme.secondaryColorLight};
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid ${(props) => props.theme.secondaryColorLight};
  border-radius: 0;
  &:hover,
  &.hover {
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: ${(props) => props.theme.secondaryColorLight};
    transform: none;
  }
  &:focus {
    outline: none;
    border: 1px solid white;
    &:before {
      content: none;
    }
  }
`;

const SkipButtonImg = styled(ButtonImg)`
  height: 24px;
`;
export const SkipButton = styled((props: ButtonProps) => {
  return <Button {...props}>{props.children}</Button>;
})`
  height: 26px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.08em;
  background-color: transparent;
  color: #152646;
  padding: 0;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 14px;
  text-transform: uppercase;
  &:hover {
    background-color: transparent;
    transform: none;
  }
`;
