import { MatSelectPage } from './mat-list';
import Customizer from './customizer';
import MatIOSHelp from './mat-ios-help';

export const PAGE_WELCOME_MAT_INTRO = 'PAGE_WELCOME_MAT_INTRO';
export const PAGE_WELCOME_MAT_MAT_SELECT = 'PAGE_WELCOME_MAT_MAT_SELECT';
export const PAGE_WELCOME_MAT_CUSTOMIZE = 'PAGE_WELCOME_MAT_CUSTOMIZE';
export const PAGE_WELCOME_MAT_SHARE_TWITTER = 'PAGE_WELCOME_MAT_SHARE_TWITTER';
export const PAGE_WELCOME_MAT_IOS_SAVE_HELP = 'PAGE_WELCOME_MAT_IOS_SAVE_HELP';

export default [
  {
    id: PAGE_WELCOME_MAT_INTRO,
    route: '/',
    title: 'Home',
  },
  {
    id: PAGE_WELCOME_MAT_MAT_SELECT,
    route: '/welcome-mat/select-mat',
    title: 'Select Mat',
    backPage: PAGE_WELCOME_MAT_INTRO,
    component: MatSelectPage,
  },
  {
    id: PAGE_WELCOME_MAT_CUSTOMIZE,
    route: '/welcome-mat/personalize',
    title: 'Personalize',
    backPage: PAGE_WELCOME_MAT_MAT_SELECT,
    component: Customizer,
  },
  {
    id: PAGE_WELCOME_MAT_IOS_SAVE_HELP,
    route: '/welcome-mat/download-ios',
    title: 'Help',
    backPage: PAGE_WELCOME_MAT_CUSTOMIZE,
    component: MatIOSHelp,
  },
];
