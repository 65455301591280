import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'winter-playful': {
    ...baseStyle,
    name: 'Winter Playful',
    thumbnail: '/images/mats/mat_winter_playful-thumb.jpg',
    bgImage: '/images/mats/mat_winter_playful-bg.jpg',
    overlayImage: '/images/mats/mat_winter_playful-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'futura-pt-condensed, Futura, Helvetica, Arial, sans-serif',
      spacing: 0.26,
      pos: { x: 0.5, y: 628 / 1056 },
      maxWidth: 750 / 1280,
      fontColor: '#2b1c00',
      fontSize: 51 / 1280,
      fontWeight: '800',
      blur: false,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#000',
      pos: {
        x: 0.5,
        y: 907 / 1056,
      },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#000',
      pos: {
        x: 0.5,
        y: (907 + 48) / 1056,
      },
    },
  },
};

export default mats;
