import PropTypes from 'prop-types';
import React from 'react';
// import { connect, Provider } from 'react-redux';
import WelcomeMat from './welcome-mat';
import DownloadImage from './save-to-file';
import matsData from './mats-data';
import { Provider, connect } from 'react-redux';
import styled from 'styled-components';
import { store } from '../app';
import {
  updateMatImageLink,
  setMatText,
  setMatType,
  setAgentName,
  setOfficeName,
} from '../reducers/welcome-mats';
import { PageHead } from '../lib/components/page-head';
import { Input } from '../lib/components/input';
import { PageContentInner } from '../lib/components/page-content';
import MatList from './mat-list';
import '../lib/customizer.css';
import remaxTextFilter from '../lib/remax-text-filter';

let mqWide = `@media only screen and (min-width: 980px)`;

let customizerMaxWidth = 580;
const PageContentCustomizer = styled((props) => {
  return (
    <div {...props}>
      <PageContentInner>{props.children}</PageContentInner>
    </div>
  );
})`
  padding-top: ${(props) => props.theme.headerHeight}px; /* .header -> height */

  @media only screen and (min-width: ${customizerMaxWidth}px) {
    margin-left: auto;
    margin-right: auto;
    min-width: ${customizerMaxWidth}px;
    width: ${customizerMaxWidth}px;
    width: 70vh;
    max-width: ${customizerMaxWidth}px;
    max-width: 85vw;
  }
  ${mqWide} {
    max-width: 59vw;
  }
  ${(props) => props.theme.mq4} {
    max-width: 800px;
  }
`;

const Page = styled.div``;

const leftWidth = 360;
const LeftSideInner = styled.div`
  background-color: white;
`;

const LeftSide = styled((props) => (
  <div {...props}>
    <LeftSideInner>{props.children}</LeftSideInner>
  </div>
))`
  display: none;
  position: fixed;
  top: ${(props) => props.theme.headerHeight}px;
  left: 0;
  bottom: 0;
  width: ${leftWidth}px;
  padding-bottom: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: ${(props) => props.theme.margin3}px;
  padding-right: ${(props) => props.theme.margin3}px;
  background-color: white;
  ${mqWide} {
    display: block;
  }
`;
const RightSide = styled((props) => (
  <div {...props}>
    <PageContentCustomizer>{props.children}</PageContentCustomizer>
  </div>
))`
  ${mqWide} {
    margin-left: ${leftWidth}px;
  }
`;

class Customizer extends React.Component {
  constructor(props) {
    super(props);
    if (props.params && props.params.idx && props.params.idx !== props.matID) {
      props.setMatType(Number(props.idx));
    }
    this.textChanged = this.textChanged.bind(this);
    this.agentChanged = this.agentChanged.bind(this);
    this.officeChanged = this.officeChanged.bind(this);
    this.setCanvas = this.setCanvas.bind(this);
    this.state = {};
  }
  setCanvas(canvas) {
    this.setState({
      wmCanvas: canvas,
    });
  }
  agentChanged(e) {
    this.props.setAgentName(e.currentTarget.value);
  }
  officeChanged(e) {
    this.props.setOfficeName(e.currentTarget.value);
  }
  textChanged(e) {
    this.props.setMatText(e.currentTarget.value);
  }
  render() {
    return (
      <Page>
        <LeftSide>
          <PageHead>Select</PageHead>
          <MatList />
        </LeftSide>
        <RightSide className="right">
          <PageHead>Personalize</PageHead>
          <WelcomeMat
            setCanvas={this.setCanvas}
            updateMatImageLink={this.props.updateMatImageLink}
            matText={remaxTextFilter(this.props.matText)}
            agentName={remaxTextFilter(this.props.agentName)}
            officeName={remaxTextFilter(this.props.officeName)}
            fontsReady={this.props.fontsReady}
            matData={matsData[this.props.matID]}
          />
          <div className="form-wrap">
            <Input
              onBlur={(e) =>
                this.props.setMatText(remaxTextFilter(e.currentTarget.value))
              }
              placeholder="Homeowner’s Name (ex: The Nash Family)"
              value={this.props.matText}
              className="customize-name"
              onChange={this.textChanged}
            />
            <Input
              onBlur={(e) =>
                this.props.setAgentName(remaxTextFilter(e.currentTarget.value))
              }
              placeholder="Agent Name (ex: Michael Klink)"
              className="customize-name"
              value={this.props.agentName}
              onChange={this.agentChanged}
            />
            <Input
              onBlur={(e) =>
                this.props.setOfficeName(remaxTextFilter(e.currentTarget.value))
              }
              placeholder="Office Name (ex: RE/MAX San Francisco)"
              className="customize-name"
              value={this.props.officeName}
              onChange={this.officeChanged}
            />
            <PageHead>Share</PageHead>
            <div className="share-buttons">
              <DownloadImage
                canvas={this.state.wmCanvas}
                dataURL={this.props.matDataURL}
              />
            </div>
          </div>
        </RightSide>
      </Page>
    );
  }
}

Customizer.propTypes = {
  matText: PropTypes.string,
  matID: PropTypes.string.isRequired,
  matDataURL: PropTypes.string,
  fontsReady: PropTypes.bool,
  updateMatImageLink: PropTypes.func,
  setMatText: PropTypes.func,
};

Customizer.defaultProps = {};

function mapStateToProps(state, ownProps) {
  const props = {
    ...ownProps,
    ...{
      matID: state.matData.matID,
      matDataURL: state.matImage.dataURL,
      matText: state.matData.matText,
      agentName: state.matData.agentName,
      officeName: state.matData.officeName,
      fontsReady: state.appState.fontsReady,
    },
  };
  return props;
}

const ReduxContainer = connect(mapStateToProps, {
  updateMatImageLink,
  setMatText,
  setMatType,
  setAgentName,
  setOfficeName,
})(Customizer);

export default (props) => (
  <Provider store={store}>
    <ReduxContainer {...props} />
  </Provider>
);
