import matsData from '../welcome-mat/mats-data';
import Analytics, { GA_CATEGORY_WELCOME_MATS } from '../analytics';

const UPDATE_MAT_IMAGE_LINK = 'UPDATE_MAT_IMAGE_LINK';
const SET_MAT_TYPE = 'SET_MAT_TYPE';
const SET_MAT_AGENT_NAME = 'SET_MAT_AGENT_NAME';
const SET_MAT_OFFICE_NAME = 'SET_MAT_OFFICE_NAME';
const SET_MAT_TEXT = 'SET_MAT_TEXT';

export const initialMatData = { matText: '', matID: '01a', agentName: '' };
export interface MatData {
  matText: string;
  matID: string;
  agentName: string;
}

interface Action {
  type: string;
  [key: string]: any;
}

// MAIN Action creators
export function updateMatImageLink(canvas: HTMLCanvasElement) {
  return {
    type: UPDATE_MAT_IMAGE_LINK,
    canvas: canvas,
  };
}

export function setMatText(matText: string) {
  return {
    type: SET_MAT_TEXT,
    matText: matText,
  };
}

export function setAgentName(agentName: string) {
  return {
    type: SET_MAT_AGENT_NAME,
    agentName: agentName,
  };
}

export function setOfficeName(officeName: string) {
  return {
    type: SET_MAT_OFFICE_NAME,
    officeName: officeName,
  };
}

export function setMatType(matID: string) {
  return (
    dispatch: (arg: { type: string; matID: string }) => void,
    getState: any
  ) => {
    Analytics.event({
      category: GA_CATEGORY_WELCOME_MATS,
      action: 'Selected Mat',
      label: matsData[matID].name,
    });
    dispatch({
      type: SET_MAT_TYPE,
      matID: matID,
    });
  };
}

export function matImage(state = {}, action: Action) {
  let newState;
  switch (action.type) {
    case UPDATE_MAT_IMAGE_LINK: {
      const dataURL = action.canvas.toDataURL();
      newState = {
        dataURL: dataURL,
      };
      return { ...state, ...newState };
    }
    default: {
      return state;
    }
  }
}

export function matData(state = initialMatData, action: Action) {
  let newState;
  switch (action.type) {
    case SET_MAT_TYPE: {
      newState = {
        matID: action.matID,
      };
      return { ...state, ...newState };
    }
    case SET_MAT_TEXT: {
      newState = {
        matText: action.matText,
      };
      return { ...state, ...newState };
    }
    case SET_MAT_AGENT_NAME: {
      newState = {
        agentName: action.agentName,
      };
      return { ...state, ...newState };
    }
    case SET_MAT_OFFICE_NAME: {
      newState = {
        officeName: action.officeName,
      };
      return { ...state, ...newState };
    }
    default: {
      return state;
    }
  }
}
