import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Analytics from './analytics';
import Hustlegraphics from './hustlegraphic/routes';
import './index.css';
import IntroPage from './intro-page';
import MainLayout from './layouts/MainLayout';
import Main from './main';
import './normalize.css';
import Terms from './terms';
import theme from './theme';
import WelcomeMats from './welcome-mat/routes';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const LogPageViews = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    Analytics.set({ page: pathname });
    Analytics.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return null;
};

const Routes = (props) => {
  return (
    <BrowserRouter {...props}>
      <ScrollToTop />
      <LogPageViews />
      <Main>
        <MainLayout>
          <Switch>
            <Route path="/welcome-mat" component={WelcomeMats} {...props} />
            <Route
              path="/hustlegraphic"
              component={Hustlegraphics}
              {...props}
            />
            <Route exact path="/" component={IntroPage} {...props} />
            <Route exact path="/terms" component={Terms} {...props} />
            <Redirect from="*" to="/" />
          </Switch>
        </MainLayout>
      </Main>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Routes />
  </ThemeProvider>,
  document.getElementById('root')
);
