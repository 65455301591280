let break1 = 500;
let break2 = 800;
let break3 = 1000;
let break4 = 1320;
let margin = 10;

let questionWidth = 300;
export const questionBreak1 = questionWidth * 2 + margin * 1 + margin * 4 + 20;
export const questionBreak2 = questionWidth * 3 + margin * 4 + margin * 6 + 20;
export const questionBreak3 = questionWidth * 4 + margin * 4 + margin * 8 + 20;

export default {
  formBorderColor: '#ddd',
  formBGColor: '#eee',
  textDark: '#2c2c2c',
  textLight: '#fff',
  primaryColor: '#dc1c2e',
  primaryColorLight: '#f31f33',
  secondaryColor: '#004e9b',
  secondaryColorLight: '#007dc3',
  secondaryColorLight2: '#0089d5',
  overlayColor: 'rgba(0, 84, 164, 0.8)',
  topPad: '90px',
  bottomPad: '50px',
  previewWidth: '600px',
  previewWidthSmall: '320px',
  generalPad: '10px',
  headerHeight: 37,
  break1: break1,
  break2: break2,
  break3: break3,
  break4: break4,
  margin: margin,
  margin1: margin,
  margin2: margin * 2,
  margin3: margin * 3,
  margin4: margin * 4,
  borderRadius: '3px',
  mq1: `@media only screen and (min-width: ${break1}px)`,
  mq2: `@media only screen and (min-width: ${break2}px)`,
  mq3: `@media only screen and (min-width: ${break3}px)`,
  mq4: `@media only screen and (min-width: ${break4}px)`,
  questionWidth: 300,
  questionMQ1: `@media only screen and (min-width: ${questionBreak1}px)`,
  questionMQ2: `@media only screen and (min-width: ${questionBreak2}px)`,
  questionMQ3: `@media only screen and (min-width: ${questionBreak3}px)`,
  fontPrimary: `'Gotham A', 'Gotham B', 'futura-pt', 'futura', 'Arial', sans-serif`,
};
