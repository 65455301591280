import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'quirky-wh': {
    ...baseStyle,
    name: 'Quirky WH',
    thumbnail: '/images/mats/mat_001_wh-thumb.jpg',
    bgImage: '/images/mats/mat_001_wh.jpg',
    overlayImage: '/images/mats/mat_001_wh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      fontSize: 0.031898148148148,
      fontColor: '#242424',
      pos: { x: 0.477, y: 0.5870975 },
      maxWidth: 0.585,
    },
    agentName: {
      ...baseStyle.agentName,
    },
    officeName: {
      ...baseStyle.officeName,
    },
  },
  'quirky-hsh': {
    ...baseStyle,
    name: 'Quirky HSH',
    thumbnail: '/images/mats/mat_001_hsh-thumb.jpg',
    bgImage: '/images/mats/mat_001_hsh.jpg',
    overlayImage: '/images/mats/mat_001_hsh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      fontSize: 0.031898148148148,
      fontColor: '#242424',
      pos: { x: 0.477, y: 0.6315 },
      maxWidth: 0.585,
    },
    agentName: {
      ...baseStyle.agentName,
    },
    officeName: {
      ...baseStyle.officeName,
    },
  },
};

export default mats;
