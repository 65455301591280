import React from 'react';
import { store, setAppState } from './app';
import { Provider, connect } from 'react-redux';
import WebFont from 'webfontloader';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fontsReady: false };
  }
  componentDidMount() {
    this.loadFonts.bind(this)();
    var root = document.documentElement;
    root.className += ' react-rendered';
  }
  loadFonts() {
    const self = this;
    const config = {
      custom: {
        // families: ['Circular:n4,n7'],
        // urls: ['/css/fonts.css'],
      },
      typekit: {
        id: 'qfm0sre',
      },
      timeout: 3000,
      active: () => {
        self.setState({ fontsReady: true });
        this.props.setAppState({ fontsReady: true });
      },
    };
    WebFont.load(config);
  }
  render() {
    const commonProps = {
      fontsReady: this.state.fontsReady,
    };
    return <div>{React.cloneElement(this.props.children, commonProps)}</div>;
  }
}

Index.propTypes = {};

Index.defaultProps = {};

function mapStateToProps(state) {
  return {};
}

const ReduxContainer = connect(mapStateToProps, { setAppState })(Index);

export default (props) => (
  <Provider store={store}>
    <ReduxContainer {...props} />
  </Provider>
);
