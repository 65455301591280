import Questions from './questions';
import ViewHustlegraphic from './view-hustlegraphic';
import HustleIOSHelp from './hustle-ios-help';

export const PAGE_HUSTLEGRAPHIC_INTRO = 'PAGE_HUSTLEGRAPHIC_INTRO';
export const PAGE_HUSTLEGRAPHIC_VIEW = 'PAGE_HUSTLEGRAPHIC_VIEW';
export const PAGE_HUSTLEGRAPHIC_QUESTIONS = 'PAGE_HUSTLEGRAPHIC_QUESTIONS';
export const PAGE_HUSTLEGRAPHIC_SHARE_TWITTER =
  'PAGE_HUSTLEGRAPHIC_SHARE_TWITTER';
export const PAGE_HUSTLEGRAPHIC_IOS_SAVE_HELP =
  'PAGE_HUSTLEGRAPHIC_IOS_SAVE_HELP';

export default [
  {
    id: PAGE_HUSTLEGRAPHIC_INTRO,
    route: '/',
    title: 'Home',
  },
  {
    id: PAGE_HUSTLEGRAPHIC_QUESTIONS,
    route: '/hustlegraphic',
    title: 'Edit',
    backPage: PAGE_HUSTLEGRAPHIC_INTRO,
    component: Questions,
  },
  {
    id: PAGE_HUSTLEGRAPHIC_VIEW,
    route: '/hustlegraphic/view',
    title: 'View/Share',
    backPage: PAGE_HUSTLEGRAPHIC_QUESTIONS,
    component: ViewHustlegraphic,
  },
  {
    id: PAGE_HUSTLEGRAPHIC_IOS_SAVE_HELP,
    route: '/hustlegraphic/download-ios',
    title: 'Help',
    backPage: PAGE_HUSTLEGRAPHIC_VIEW,
    component: HustleIOSHelp,
  },
];
