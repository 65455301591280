import React from 'react';
import MyInput from './answer-input';

export default class extends React.Component {
  render() {
    const {  hasLimit } = this.props;
    return (
      <div>
        <MyInput
          tabIndex={this.props.tabIndex || ''}
          className="main-input"
          value={this.props.answer ? this.props.answer.number : ''}
          type="number"
          min="0"
          step="1"
          pattern="[0-9]"
          onChange={(e) => {
            const value = Number(e.currentTarget.value);
            if(hasLimit) {
              if (value.toString().length <= 4) {
                this.props.setAnswer({ number: value });
              }
            } else {
              this.props.setAnswer({ number: value });
            }
            
          }}
        />
      </div>
    );
  }
}
