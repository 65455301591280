import React from 'react';
import { Input } from '../lib/components/input';
import remaxTextFilter from '../lib/remax-text-filter';
import { formatPhoneNumber } from './answer-tools';

// type Props = {
//   info: Info;
//   setInfo: (string, string) => void;
// };

export default ({ info, setInfo }) => {
  return (
    <div>
      <Input
        tabIndex="400"
        onChange={(e) => setInfo('agentName', e.currentTarget.value)}
        value={info.agentName}
        onBlur={(e) =>
          setInfo('agentName', remaxTextFilter(e.currentTarget.value))
        }
        placeholder="Agent name (required)"
      />
      <Input
        tabIndex="401"
        onChange={(e) => setInfo('officeName', e.currentTarget.value)}
        value={info.officeName}
        onBlur={(e) =>
          setInfo('officeName', remaxTextFilter(e.currentTarget.value))
        }
        placeholder="Office name (required)"
      />
      <Input
        tabIndex="402"
        onChange={(e) => setInfo('phoneNumber', e.currentTarget.value)}
        value={formatPhoneNumber(info.phoneNumber)}
        placeholder="Phone number (required)"
      />
      <Input
        tabIndex="403"
        onChange={(e) => setInfo('email', e.currentTarget.value)}
        value={info.email}
        placeholder="Email (optional)"
      />
    </div>
  );
};
