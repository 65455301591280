import classNames from 'classnames';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import Analytics, {
  GA_CATEGORY_CUSTOM_COMMERCIAL,
  GA_CATEGORY_EXIT_REMAX,
  GA_CATEGORY_HUSTLEGRAPHIC,
  GA_CATEGORY_WELCOME_MATS,
} from './analytics';
import { store } from './app';
import imgHustlegraphic from './images/homepage/hustlegraphic_logo.png';
import imgCanada from './images/homepage/RMX_HomepageTile_CanadianCommercial.jpg';
import imgCommunity from './images/homepage/RMX_HomepageTile_CommunityMessage.jpg';
import imgCustomCommercial from './images/homepage/RMX_HomepageTile_CustomizeCommercial.jpg';
import imgDefault from './images/homepage/RMX_HomepageTile_GenericTile.jpg';
import imgToolsVid from './images/homepage/RMX_HomepageTile_HighlightSkills.jpg';
import imgRecruitment from './images/homepage/RMX_HomepageTile_RecruitmentVideo.jpg';
import imgVideoEditor from './images/homepage/RMX_HomepageTile_VideoEditor.jpg';
import imgWelcomeMat from './images/homepage/RMX_HomepageTile_WelcomeMat.jpg';
import LogoLockup from './images/remax-hustle-lockup';
import Link from './lib/components/link';
import { resetHustlegraphics } from './reducers/hustlegraphics';

const dispatch = store.dispatch;

const colors = {
  buttonBG: '#fff',
  titleText: '#fff',
  buttonText: '#20386e',
  lockup: '#1b4798',
};

const minCardWidth = 300;
const cardAspectRatio = 1.03125;
const breakCols2 = minCardWidth * 2;
const breakCols3 = minCardWidth * 3;
// const breakCols4 = minCardWidth * 4;
// const breakCols5 = minCardWidth * 5;
// const breakCols6 = minCardWidth * 6;
const maxColumns = 3;
const breakMax = minCardWidth * (maxColumns + 1);
const fontWidthRatio = 29 / minCardWidth;
const footerFontRatio = 0.06;
const footerFontMax = '23px';

const logoHeight = 17;
const logoWidth = logoHeight * (446 / 42);
const headerPad = Math.round((3.5 * logoHeight - logoHeight) / 2);
const cardPaddingBottom = '15%';
const buttonHeightEstimate = 30;
const cardMargin = 2;
const innerMargin = 25;

const Header = styled((p) => (
  <div {...p}>
    <a
      className="logo"
      href="https://www.remax.com"
      onClick={() =>
        Analytics.event({
          category: GA_CATEGORY_EXIT_REMAX,
          action: GA_CATEGORY_EXIT_REMAX,
        })
      }
    >
      <LogoLockup />
    </a>
  </div>
))`
  background-color: white;
  width: 100%;
  padding-top: ${headerPad}px;
  padding-bottom: ${headerPad + 1}px;
  padding-left: ${innerMargin}px;
  line-height: 0;
  font-size: 0;
  .logo {
    display: inline-block;
    margin: 0;
    line-height: 0;
    svg {
      width: 100%;
      max-width: ${logoWidth}px;
      display: inline-block;
    }
  }
`;

const CardButton = styled(({ hidden = false, children, className }) => {
  return (
    <div className={className} aria-hidden={hidden || false}>
      <span>{children}</span>
    </div>
  );
})`
  position: relative;
  display: inline-block;
  background-color: ${colors.buttonBG};
  color: ${colors.buttonText};
  font-family: ${(p) => p.theme.fontPrimary};
  font-weight: 800;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 11px;
  padding: 1em 1.364em;
  line-height: 0;
  box-shadow: 4px 2px 10px rgb(0 0 0 / 0.1);
  border: none;
  border-radius: 999px;
  &:focus {
    outline: none;
  }
  tabindex: -1;
  z-index: 0;
  ${(p) => p.hidden && 'visibility: hidden;'}
  text-align: center;
  > span {
    display: inline-block;
    line-height: 1.1em;
    margin-bottom: -0.181818em;
    margin-top:    -0.181818em;
  }
  a:hover &::before
  {
    content: ' ';
    display: block;
    position: absolute;
    border-radius 999px;
    border: 1px solid white;
  }
  a:hover &::before
  {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius 999px;
    background-color: hsl(210 90% 15% / .15);
    z-index: -1;
  }
  a:hover & {
    color: ${colors.buttonBG};
    background: none;
    box-shadow: none;
  }
`;

const CardTag = styled(({ hidden = false, children, className }) => {
  return (
    <div className={className} aria-hidden={hidden || false}>
      <span>{children}</span>
    </div>
  );
})`
  position: absolute;
  display: inline-block;
  background-color: ${(p) => p.theme.primaryColor};
  color: white;
  font-family: ${(p) => p.theme.fontPrimary};
  font-weight: 700;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 11px;

  top: ${innerMargin}px;
  left: 0;
  padding: 1em 1.364em;
  padding-left: ${innerMargin}px;
  line-height: 0;
  border: none;
  border-radius: 0 999px 999px 0;
  z-index: 0;
  ${(p) => p.hidden && 'visibility: hidden;'}
  text-align: left;
  > span {
    display: inline-block;
    line-height: 1.1em;
    margin-bottom: -0.181818em;
    margin-top: -0.181818em;
  }
`;

const CardTitle = styled.h2`
  color: ${colors.titleText};
  font-family: ${(p) => p.theme.fontPrimary};
  font-weight: bold;
  letter-spacing: 0;
  font-size: 22px;
  max-width: 11em;
  max-width: calc(min(11em, var(--maxFontSize) * 9.1));
  margin-bottom: 13px;
  text-shadow: 4px 2px 10px rgb(0 0 0 / 0.3);

  --maxFontSize: 36px;
  font-size: 36px;
  font-size: calc(min(var(--maxFontSize), 100vw * ${fontWidthRatio}));

  @media only screen and (min-width: ${breakCols2}px) {
    --maxFontSize: 32px;
    font-size: 32px;
    font-size: calc(min(var(--maxFontSize), 100vw * ${fontWidthRatio / 2}));
  }
  @media only screen and (min-width: ${breakCols3}px) {
    font-size: calc(min(var(--maxFontSize), 100vw * ${fontWidthRatio / 3}));
  }
  @media only screen and (min-width: ${breakMax}px) {
    font-size: calc(
      min(var(--maxFontSize), ${breakMax}px * ${fontWidthRatio / maxColumns})
    );
  }
`;

const Card = styled(
  ({
    alt,
    buttonText,
    image,
    tagText,
    footerText,
    text,
    title,
    to,
    onClick,
    ...p
  }) => {
    return (
      <div {...p} className={classNames(p.className, 'card')}>
        <Link className="link" to={to} onClick={onClick}>
          {image ? <div className="bgImage" /> : null}
          <div className="text">
            <CardTitle>{title}</CardTitle>
            {buttonText && (
              <CardButton className={{ hidden: !buttonText }}>
                {buttonText}
              </CardButton>
            )}
          </div>
          {footerText && (
            <div className="footer">
              <span>{footerText}</span>
            </div>
          )}
          {tagText && <CardTag>{tagText}</CardTag>}
        </Link>
      </div>
    );
  }
)`
  position: relative;
  height: 0;
  padding-top: ${cardAspectRatio * 100}%;
  width: 100%;
  background-color: ${(p) => p.theme.color};
  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${(p) => (p.image ? `background-image: url(${p.image});\n` : '')}
    background-size: cover;
    background-position: center center;
  }
  .text {
    position: relative;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${cardPaddingBottom};
    height: calc(${buttonHeightEstimate}px + ${cardPaddingBottom});
    line-height: calc(${buttonHeightEstimate}px + ${cardPaddingBottom});
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.14em;
    color: white;
    background-color: ${(p) => p.theme.secondaryColorLight};

    font-size: ${footerFontRatio * minCardWidth}px;
    font-size: calc(
      min(
        ${footerFontMax},
        (
            ${footerFontRatio} * var(--totalWidth) -
              (var(--cardGap) * (var(--numColumns) - 1))
          ) / var(--numColumns)
      )
    );

    display: flex;
    align-items: center;
    justify-content: center;
  }
  & a .bgImage {
    transition: transform 0.1s;
  }
  & a:hover {
    .bgImage {
      transform: scale(1.05);
      transition: transform 0.2s;
    }
  }
  .footer span {
    transition: transform 0.15s;
  }
  // .link:hover {
  //   .footer span {
  //     transform: scale(1.03);
  //     transition: transform 0.25s;
  //   }
  // }
  .link {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    padding: ${innerMargin}px;
    padding-bottom: ${cardPaddingBottom};
    display: flex;
    align-items: flex-end;

    > * {
      pointer-events: none;
    }
  }
  a.link {
    text-decoration: none;
  }
`;

const CardList = styled((p) => {
  return (
    <ul {...p} className={classNames(p.className, 'cards')}>
      <li>
        <Card
          image={imgCustomCommercial}
          title={<>Customize a Commercial</>}
          buttonText={<>Start Customizing</>}
          to="https://videotool.remaxhustle.com/custom/create/welcome"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_CUSTOM_COMMERCIAL,
              action: 'Make Video - Custom',
            });
          }}
        />
      </li>
      <li>
        <Card
          image={imgVideoEditor}
          title={<>Use the Hustle Video Editor</>}
          buttonText={<>Create Your Video</>}
          to="https://videoeditor.remaxhustle.com/"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_CUSTOM_COMMERCIAL,
              action: 'Make Video - Video Editor',
            });
          }}
        />
      </li>

      <li>
        <Card
          image={imgRecruitment}
          title={<>Share a Recruitment Video</>}
          buttonText={<>Start Recruiting</>}
          to="https://videotool.remaxhustle.com/recruitment/create/commercials"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_CUSTOM_COMMERCIAL,
              action: 'Make Video - Recruitment',
            });
          }}
        />
      </li>
      <li>
        <Card
          image={imgWelcomeMat}
          title={<>Send a Digital Welcome&nbsp;Mat</>}
          buttonText={<>Welcome a Client</>}
          to="/welcome-mat/select-mat"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_WELCOME_MATS,
              action: 'Make Mat',
            });
          }}
        />
      </li>
      <li>
        <Card
          image={imgToolsVid}
          title={<>Highlight Your&nbsp;Skills</>}
          buttonText={<>Show off Your Tools</>}
          to="https://videotool.remaxhustle.com/tools/create/welcome"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_CUSTOM_COMMERCIAL,
              action: 'Make Video - Tools',
            });
          }}
        />
      </li>
      <li>
        <Card
          image={imgHustlegraphic}
          title={<>Create a Hustlegraphic</>}
          buttonText={<>Create Infographic</>}
          to="/hustlegraphic"
          onClick={() => {
            dispatch(resetHustlegraphics());
            Analytics.event({
              category: GA_CATEGORY_HUSTLEGRAPHIC,
              action: 'Make Hustlegraphic',
            });
          }}
        />
      </li>
      <li>
        <Card
          image={imgCommunity}
          title={<>Create a Community Message</>}
          buttonText={<>Show your Support</>}
          to="https://videotool.remaxhustle.com/iam/create/welcome"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_CUSTOM_COMMERCIAL,
              action: 'Make Video - Community Response',
            });
          }}
        />
      </li>
      <li>
        <Card
          image={imgDefault}
          to="#"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_CUSTOM_COMMERCIAL,
              action: 'Make Video - Recruitment',
            });
          }}
        />
      </li>
      <li>
        <Card
          image={imgCanada}
          title={<>Customize a Canadian Commercial</>}
          buttonText={<>Start Customizing</>}
          to="https://videotool.remaxhustle.com/canada/create/welcome"
          onClick={() => {
            Analytics.event({
              category: GA_CATEGORY_CUSTOM_COMMERCIAL,
              action: 'Make Video - Canadian Commercial',
            });
          }}
        />
      </li>
    </ul>
  );
})`
  display: block;
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  margin-left: -10px;
  margin-right: -10px;
  margin: ${cardMargin * -1}px;
  overflow: hidden;

  &::after {
    content: ' ';
    width: 0;
    height: 0;
    clear: both;
  }

  li {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    padding: ${cardMargin}px;
    width: 100%;
    flex-basis: auto;
  }

  --numColumns: 1;
  --totalWidth: 100vw;
  --cardMargin: ${cardMargin}px;
  --cardGap: ${cardMargin * 2}px;
  @media only screen and (min-width: ${breakCols2}px) {
    li {
      float: left;
      width: ${100 / 2}%;
    }
    --numColumns: 2;
  }
  @media only screen and (min-width: ${breakCols3}px) {
    li {
      width: ${100 / 3}%;
    }
    --numColumns: 3;
  }
  @media only screen and (min-width: ${breakMax}px) {
    --totalWidth: ${breakMax}px;
  }
`;

const IntroPageContent = styled.div`
  --innerMargin: 20px;

  margin: 0;
  padding: 0;
  @media only screen and (min-width: ${breakMax}px) {
    max-width: ${breakMax}px;
    margin-left: auto;
    margin-right: auto;
  }
  overflow: hidden;
`;

const IntroPage = () => {
  return (
    <>
      <IntroPageContent>
        <Header />
        <CardList />
      </IntroPageContent>
    </>
  );
};

export default withRouter(IntroPage);
