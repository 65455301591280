import { questionBreak1 } from './theme';

let viewport = window;
let prefix = 'inner';
if (!('innerWidth' in window)) {
  prefix = 'client';
  viewport = document.documentElement || document.body;
}

export const viewportDimensions = () => {
  return {
    width: viewport[prefix + 'Width'],
    height: viewport[prefix + 'Height'],
  };
};

export const isMobileWidth = () => {
  return viewportDimensions().width < questionBreak1;
};
