export default [
  '2 girls 1 cup',
  '2g1c',
  'a$$',
  'a55',
  'acrotomophilia',
  'alabama hot pocket',
  'alaskan pipeline',
  'anal',
  'anilingus',
  'anus',
  'anuses',
  'apeshit',
  'arse',
  'arsehole',
  'arseholes',
  'arses',
  'ass',
  'asses',
  'asshat',
  'asshats',
  'asshole',
  'assholes',
  'assmunch',
  'assmunches',
  'auto erotic',
  'autoerotic',
  'babeland',
  'baby batter',
  'baby juice',
  'ball gag',
  'ball gravy',
  'ball kicking',
  'ball licking',
  'ball sucking',
  'ballsack',
  'ballsacks',
  'bangbros',
  'bareback',
  'barely legal',
  'barenaked',
  'bastard',
  'bastardo',
  'bastinado',
  'bbw',
  'bdsm',
  'beaner',
  'beaners',
  'beaver cleaver',
  'beaver lips',
  'bestiality',
  'big black',
  'big breasts',
  'big knockers',
  'big tits',
  'bimbos',
  'birdlock',
  'bitch',
  'bitchy',
  'bitches',
  'bitched',
  'black cock',
  'blonde action',
  'blonde on blonde action',
  'blow your load',
  'blowjob',
  'blowjob',
  'blowjobs',
  'blue waffle',
  'blumpkin',
  'bollocks',
  'bondage',
  'boner',
  'boners',
  'boob',
  'boobs',
  'booty call',
  'brown showers',
  'brunette action',
  'bukkake',
  'bulldyke',
  'bullet vibe',
  'bullshit',
  'bung hole',
  'bunghole',
  'busty',
  'butt',
  'buttcheek',
  'buttcheeks',
  'butthole',
  'buttholes',
  'butts',
  'cameltoe',
  'camgirl',
  'camslut',
  'camwhore',
  'carpet muncher',
  'carpetmuncher',
  'chink',
  'chocolate rosebuds',
  'circlejerk',
  'cleveland steamer',
  'clit',
  'clitoris',
  'clits',
  'clover clamps',
  'clusterfuck',
  'clusterfucks',
  'cock',
  'cocks',
  'cocksucker',
  'cocksuckers',
  'coon',
  'coons',
  'coprolagnia',
  'coprophilia',
  'cornhole',
  'creampie',
  'cum',
  'cumming',
  'cunnilingus',
  'cunt',
  'cunts',
  'cunty',
  'darkie',
  'darkies',
  'darky',
  'date rape',
  'date rapes',
  'daterape',
  'daterapes',
  'deep throat',
  'deepthroat',
  'dendrophilia',
  'dick',
  'dickhead',
  'dickheads',
  'dicklicker',
  'dicklickers',
  'dicks',
  'dicksucker',
  'dicksuckers',
  'dildo',
  'dingleberries',
  'dingleberry',
  'dirty pillows',
  'dirty sanchez',
  'dog style',
  'doggie style',
  'doggiestyle',
  'doggy style',
  'doggystyle',
  'dolcett',
  'domination',
  'dominatrix',
  'dommes',
  'donkey punch',
  'double dong',
  'double penetration',
  'dp action',
  'dry hump',
  'dvda',
  'dyke',
  'eat my ass',
  'ecchi',
  'ejaculation',
  'erotic',
  'erotism',
  'escort',
  'eunuch',
  'faggot',
  'faggots',
  'fap',
  'fappening',
  'faps',
  'fart',
  'fecal',
  'felch',
  'fellatio',
  'feltch',
  'female squirting',
  'femdom',
  'figging',
  'fingerbang',
  'fingering',
  'fisting',
  'foot fetish',
  'footjob',
  'frotting',
  'fuck buttons',
  'fuck',
  'fuckboy',
  'fuckboys',
  'fucked',
  'fucker',
  'fuckers',
  'fuckgirl',
  'fuckgirls',
  'fuckhead',
  'fuckin',
  'fucking',
  'fucks',
  'fucktards',
  'fudge packer',
  'fudge packers',
  'fudgepacker',
  'fudgepackers',
  'futanari',
  'g-spot',
  'g-spots',
  'gang bang',
  'gay sex',
  'genitals',
  'giant cock',
  'girl on top',
  'girl on',
  'girls gone wild',
  'goatcx',
  'goatse',
  'goatsex',
  'god damn',
  'gokkun',
  'golden shower',
  'golden showers',
  'goo girl',
  'goodpoop',
  'gook',
  'goregasm',
  'grope',
  'group sex',
  'guro',
  'hand job',
  'hand jobs',
  'handjob',
  'handjobs',
  'hard core',
  'hardcore',
  'hentai',
  'homoerotic',
  'honkey',
  'honkeys',
  'hooker',
  'hot carl',
  'hot chick',
  'how to kill',
  'how to murder',
  'huge fat',
  'humping',
  'homo',
  'homos',
  'incest',
  'injun',
  'intercourse',
  'jackoff',
  'jackoffs',
  'jacksoff',
  'jail bait',
  'jailbait',
  'jerkoff',
  'jerkoffs',
  'jigaboo',
  'jigaboos',
  'jiggaboo',
  'jiggaboos',
  'jiggerboo',
  'jiggerboos',
  'jizz',
  'jizzes',
  'jizzing',
  'juggs',
  'kike',
  'kinbaku',
  'kinkster',
  'kinky',
  'knobbing',
  'knickers',
  'labe',
  'labes',
  'labia',
  'labias',
  'leather restraint',
  'leather straight jacket',
  'lemonparty',
  'lolita',
  'lolitas',
  'lovemaking',
  'make me come',
  'male squirting',
  'masturbate',
  'masturbates',
  'meatdrape',
  'meatdrapes',
  'menage a trois',
  'milf',
  'milfs',
  'missionary position',
  'motherfucker',
  'mound of venus',
  'mr hands',
  'muffdiver',
  'muffdivers',
  'muffdiving',
  'nambla',
  'nawashi',
  'nazi',
  'nazis',
  'negro',
  'neonazi',
  'neonazis',
  'nig nog',
  'nigga',
  'niggar',
  'niggars',
  'niggas',
  'nigger',
  'niggers',
  'niggerz',
  'nimphomania',
  'nipple',
  'nipples',
  'nsfw images',
  'nude',
  'nudity',
  'nutsack',
  'nympho',
  'nutsacks',
  'nymphos',
  'nymphomania',
  'octopussy',
  'omorashi',
  'one cup two girls',
  'one guy one jar',
  'orgasm',
  'orgasms',
  'orgies',
  'orgy',
  'paedobear',
  'paedobears',
  'paedophile',
  'paedophiles',
  'paki',
  'panties',
  'panty',
  'pedobear',
  'pedobears',
  'pedophile',
  'pedophiles',
  'pegging',
  'penis',
  'penises',
  'phone sex',
  'piece of shit',
  'piss pig',
  'pissing',
  'pisspig',
  'playboy',
  'pleasure chest',
  'pole smoker',
  'ponyplay',
  'poof',
  'poon',
  'poontang',
  'poop chute',
  'poop',
  'poopchute',
  'porn',
  'porno',
  'pornography',
  'prince albert piercing',
  'pthc',
  'pube',
  'pubes',
  'punany',
  'pussy',
  'pussies',
  'queaf',
  'queef',
  'queef',
  'quim',
  'raghead',
  'ragheads',
  'raging boner',
  'rape',
  'rapes',
  'raping',
  'rapist',
  'rectum',
  'rectums',
  'redskin',
  'reverse cowgirl',
  'rimjob',
  'rimming',
  'rosy palm and her 5 sisters',
  'rosy palm',
  'rusty trombone',
  's&m',
  'sadism',
  'santorum',
  'scat',
  'scats',
  'schlong',
  'schlongs',
  'scissoring',
  'semen',
  'sex',
  'sexo',
  'sexy',
  'shaved beaver',
  'shaved pussy',
  'shemale',
  'shibari',
  'shit',
  'shitbiscuit',
  'shitbiscuits',
  'shitblimp',
  'shitblimps',
  'shitdick',
  'shitdicks',
  'shite',
  'shites',
  'shitfuck',
  'shitfucks',
  'shithead',
  'shitheads',
  'shitheel',
  'shitheels',
  'shithole',
  'shitholes',
  'shitlick',
  'shitlicks',
  'shits',
  'shitty',
  'shota',
  'shrimping',
  'skeet',
  'slanteye',
  'slut',
  'sluts',
  'smut',
  'snatch',
  'snatches',
  'snowballing',
  'sodomize',
  'sodomy',
  'spic',
  'spics',
  'splooge moose',
  'splooge',
  'splooges',
  'spooge',
  'spooges',
  'spread legs',
  'spunk',
  'spunks',
  'strap on',
  'strapon',
  'strapons',
  'strappado',
  'strip club',
  'style doggy',
  'suck',
  'sucks',
  'suicide girls',
  'sultry women',
  'swastika',
  'swastikas',
  'swinger',
  'swingers',
  'tainted love',
  'taste my',
  'tea bagging',
  'threesome',
  'threesomes',
  'throating',
  'tied up',
  'tight white',
  'tit',
  'tits',
  'titties',
  'titty',
  'tongue in a',
  'topless',
  'tosser',
  'towelhead',
  'tranny',
  'tribadism',
  'tub girl',
  'tub girls',
  'tubgirl',
  'tubgirls',
  'turd',
  'tushy',
  'twat',
  'twats',
  'twink',
  'twinkie',
  'twinks',
  'twinkies',
  'two girls one cup',
  'undressing',
  'upskirt',
  'upskirts',
  'urethra play',
  'urophilia',
  'vagina',
  'vaginas',
  'venus mound',
  'vibrator',
  'violet wand',
  'vorarephilia',
  'voyeur',
  'vulva',
  'vulvas',
  'wank',
  'wanks',
  'wet dream',
  'wetback',
  'wetbacks',
  'white power',
  'wop',
  'wrapping men',
  'wrinkled starfish',
  'xx',
  'xxx',
  'yaoi',
  'yellow showers',
  'yiffy',
  'zoophilia',
  '🖕',
];
