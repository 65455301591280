import React from 'react';
import styled from 'styled-components';

const PageHeadH1 = styled.h1`
  display: inline-block;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 300px;
  &:before,
  &:after {
    content: ' ';
    display: block;
    height: 1px;
    width: 50px;
    background-color: #2c2c2c;
    position: absolute;
    bottom: 50%;
  }
  &:before {
    left: -70px;
  }
  &:after {
    right: -70px;
  }
`;
const PageHeadWrap = styled.div`
  text-align: center;
  overflow-x: hidden;
`;

export const PageHead = (props) => (
  <PageHeadWrap>
    <PageHeadH1>{props.children}</PageHeadH1>
  </PageHeadWrap>
);
