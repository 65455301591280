// TWITTER STUFF
export const TW_AUTHORIZED = 'TW_AUTHORIZED';
export const TW_WAITING_FOR_REQ_TOKEN = 'TW_WAITING_FOR_REQ_TOKEN';
export const TW_RECEIVED_REQ_TOKEN = 'TW_RECEIVED_REQ_TOKEN';
export const TW_WAITING_FOR_ACC_TOKEN = 'TW_WAITING_FOR_ACC_TOKEN';
export const TW_RECEIVED_ACC_TOKEN = 'TW_RECEIVED_ACC_TOKEN';
export const TW_NO_AUTH = 'TW_NO_AUTH';
const TWITTER_SET = 'TWITTER_SET';

interface Action {
  type: string;
  [x: string]: any;
}

type AuthState = string;
export interface TwitterState {
  authState: AuthState;
  authError: string | null;
  postError: string | null;
  postURL: string;
}

export const initialTwitterState: TwitterState = {
  authState: TW_NO_AUTH,
  authError: null,
  postError: null,
  postURL: '',
};

export function twitter(state = initialTwitterState, action: Action) {
  let newState;
  switch (action.type) {
    case TWITTER_SET: {
      newState = action.props;
      return { ...state, ...newState };
    }
    default: {
      return state;
    }
  }
}

export const changeAuthState = (authState: AuthState) => {
  return {
    type: TWITTER_SET,
    props: { authState: authState },
  };
};

export const setProps = (props: { [key: string]: any }) => {
  return {
    type: TWITTER_SET,
    props: props,
  };
};
