import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'cozy-wh': {
    ...baseStyle,
    isNew: true,
    name: 'Cozy WH',
    thumbnail: '/images/mats/mat_life_is_made_for_cozy-thumb.jpg',
    bgImage: '/images/mats/mat_life_is_made_for_cozy-bg.jpg',
    overlayImage: '/images/mats/mat_life_is_made_for_cozy-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'korolev, Futura, sans-serif',
      spacing: 0.18,
      pos: { x: 0.498, y: 0.649395 },
      maxWidth: 0.55,
      fontColor: '#fff',
      fontSize: 0.03689814815,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 907 / 1026 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: (907 + 48) / 1026 },
    },
  },
  'cozy-hsh': {
    ...baseStyle,
    name: 'Cozy HSH',
    thumbnail: '/images/mats/mat_life_is_made_for_cozy-thumb.jpg',
    bgImage: '/images/mats/mat_life_is_made_for_cozy-bg.jpg',
    overlayImage: '/images/mats/mat_life_is_made_for_cozy-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'korolev, Futura, sans-serif',
      spacing: 0.155,
      pos: { x: 0.49, y: 0.6315 },
      maxWidth: 0.55,
      fontColor: '#fff',
      fontSize: 0.03485185185,
    },
    agentName: {
      ...baseStyle.agentName,
      fontColor: '#fff',
      pos: { x: 0.5, y: 907 / 1056 },
    },
    officeName: {
      ...baseStyle.officeName,
      fontColor: '#fff',
      pos: { x: 0.5, y: (907 + 48) / 1056 },
    },
  },
};

export default mats;
