import { Mats } from './mat-data-type';
import baseStyle from './mat-base';

const mats: Mats = {
  'suburban-wh': {
    ...baseStyle,
    name: 'Suburban WH',
    thumbnail: '/images/mats/mat_002_wh-thumb.jpg',
    bgImage: '/images/mats/mat_002_wh.jpg',
    overlayImage: '/images/mats/mat_002_wh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'superclarendon, Georgia, serif',
      fontWeight: '700',
      spacing: 0.09,
      pos: { x: 0.5, y: 0.5491375 },
      maxWidth: 0.47,
      fontColor: '#000',
      fontSize: 0.03005555556,
    },
    agentName: {
      ...baseStyle.agentName,
      pos: { x: 0.5, y: 0.8409575 },
    },
    officeName: {
      ...baseStyle.officeName,
      pos: { x: 0.5, y: 0.88635 },
    },
  },
  'suburban-hsh': {
    ...baseStyle,
    name: 'Suburban HSH',
    thumbnail: '/images/mats/mat_002_hsh-thumb.jpg',
    bgImage: '/images/mats/mat_002_hsh.jpg',
    overlayImage: '/images/mats/mat_002_hsh-overlay.png',
    buyerName: {
      ...baseStyle.buyerName,
      font: 'brandon-grotesque, Futura, sans-serif',
      fontWeight: '900',
      spacing: 0.14,
      pos: { x: 0.496, y: 0.5679225 },
      maxWidth: 0.53,
      fontColor: '#000',
      fontSize: 0.02657407407,
    },
    agentName: {
      ...baseStyle.agentName,
      pos: { x: 0.5, y: 0.8409575 },
    },
    officeName: {
      ...baseStyle.officeName,
      pos: { x: 0.5, y: 0.88635 },
    },
  },
};

export default mats;
